/* Modal.module.css */

.modal {
	display: none;
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%; /* Modifique para 100% para cobrir toda a altura da tela */
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.4);
  }
  
  .show {
	display: block;
  }
  
  .dialog {
	position: relative;
	margin: 10% auto; /* Ajuste a margem para centralizar melhor o modal */
	padding: 20px;
	width: 80%; /* Aumente a largura para dispositivos móveis */
	background-color: #fff;
	border-radius: 15px; /* Utilize border-radius em vez de border para bordas arredondadas */
  }
  
  .title {
	font-size: 1.25rem;
  }
  
  .header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #ddd;
	padding-bottom: 10px;
  }
  
  .body {
	padding: 10px; /* Adicione padding para melhorar a legibilidade */
  }
  
  .footer {
	display: flex;
	justify-content: flex-end; /* Ajuste para 'flex-end' para alinhar botões à direita */
	padding: 10px;
  }
  
  .btn {
	cursor: pointer;
	padding: 10px 20px;
	margin-left: 10px;
	border-radius: 5px; /* Adicione border-radius para botões arredondados */
  }
  
  .btnprimary {
	background-color: #007bff;
	color: #fff;
	border: none;
  }
  
  .btnsecondary {
	background-color: #ccc;
	color: #333;
	border: none;
  }
  
  .buttoncancelar {
	width: auto;
	background: #a70606;
	font-size: 18px; /* Reduza o tamanho da fonte para telas menores */
	color: white;
	font-family: 'sans-serif';
	border: 0 none;
	border-radius: 35px;
	cursor: pointer;
	transition: 0.3s;
	padding: 7px 20px; /* Ajuste o padding conforme necessário */
	margin: 10px auto; /* Centralize o botão com margin automática */
  }
  
  .buttoncancelar:hover {
	background: #870303; /* Cor quando hover */
	box-shadow:
	  0 0 0 3px #ffffffd8,
	  0 0 0 4px #870303; /* Cor da borda quando hover */
  }
  
  /* Media query para dispositivos móveis */
  @media (max-width: 768px) {
	.dialog {
	  margin: 5% auto; /* Aumente a margem superior e inferior para dispositivos móveis */
	  width: 95%; /* Ajuste a largura para a maioria da largura da tela */
	}
  
	.title {
	  font-size: 20px; /* Aumente o tamanho da fonte para legibilidade */
	}
  
	.btn, .buttoncancelar {
	  font-size: 16px; /* Reduza o tamanho da fonte para os botões */
	  padding: 10px 15px; /* Reduza o padding para os botões */
	}
  }
  