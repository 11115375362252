.gfbox {
    border: 1px solid #ddd;
    border-radius: 10px;
    margin: 1% auto;
    width: flex;
    max-width: 800px;
    background-color: #f7f7f7;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    font-family: 'Roboto', sans-serif;
}

.gftitle {
    text-align: center;
    font-size: 24px;
    color: #222121;
    margin-bottom: 20px;
}

.gfcoluns {
    display:inline-flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Usar space-between para separar os elementos */
    
}

.gfcolum {
    flex: 1; /* Distribui o espaço igualmente entre os elementos */
    margin: 0 10px; /* Adicione margens para espaçamento entre os elementos */
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    background-color: #fff;
    cursor: pointer;
    position: relative;
}

.gfcolum:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.grpercent {
    text-align: center;
    font-weight: 600;
    color: #333;
    font-size: 20px;
    margin-bottom: 10px;
    position: relative;
    z-index: 2;
}

.grbackbar {
    width: 100%;
    height: 10px;
    background-color: #ddd;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.grblue {
    width: 0%;
    width: var(--saldo);
    height: 100%;
    background-color: #007acc;
    border-radius: 5px;
    transition: width 0.5s;
}

.grred {
    width: 0%;
    width: var(--saldo);
    height: 100%;
    background-color: #ff4040;
    border-radius: 5px;
    transition: width 0.5s;
}

.grdesc {
    text-align: justify;
    font-size: 16px;
    color: #222121;
}

.DI_BL, .NOME_REDUZIDO {
    text-align: center;
    font-weight: 600;
    color: #333;
    font-size: 18px;
    margin-top: 10px;
}

.linha {
    height: 1px;
    background: linear-gradient(to right, #ccc, #333, #ccc);
    margin: 15px 0;
}

.manifestado {
    text-align: justify;
    line-height: 150%;
    font-size: 16px;
    color: #222121;
    margin-top: 10px;
}

.manifestado a {
    color: #007acc;
    text-decoration: underline;
    transition: color 0.2s;
}

.manifestado a:hover {
    color: #005b99;
}
