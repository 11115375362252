.content {
	margin: 0 auto;
	font-size: 19px;
	width: auto;
	color: #424242;
	background-color: #f5f5f5;
	display: grid;
	border-radius: 10px;
	padding: 10px 15px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Adiciona uma sombra ao redor da borda */
	padding-bottom: 5%;
}
  
/* Estilos da barra de navegação */
.nav {
	display: flex;
	width: auto;
	align-items: center;
	color: #424242;
	gap: 2%;
	flex-wrap: nowrap; /* Impede que os itens quebrem para a próxima linha */
	padding-bottom: 2%;
}

.navbar {
	padding: 10px;
	border-radius: 10px;
	color: #424242;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #a1a1a14d;
}

.active {
	padding: 10px;
	border-radius: 10px;
	color: #f2f2f2;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #c91a1a;
}
.tabContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #ddd;
}

.tabButton {
  padding: 5px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  outline: none;
}

.tabButton:hover {
  background-color: #0057b300;
}

.radioGroup {
  display: flex;
  justify-content: center; /* Centraliza os botões horizontalmente */
  gap: 20px; /* Espaçamento entre os botões */
  margin-bottom: 20px; /* Espaçamento inferior */
}

.radioGroup label {
  display: flex;
  align-items: center; /* Alinha o texto e o botão verticalmente */
  font-size: 16px;
  cursor: pointer;
}

.checklist {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.checklist h2 {
  text-align: center;
  margin-bottom: 20px;
}

.checklist label {
  display: block;
  margin-bottom: 10px;
}

.checklist input[type="text"],
.checklist input[type="number"],
.checklist input[type="datetime-local"],
.checklist textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.checklist button {
  width: 100%;
  padding: 10px;
  background-color: #02670f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.photoList ul {
  list-style: none;
  padding: 0;
}

.photoList li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.photoList li:hover {
  background-color: #f0f0f0;
}

.thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 10px;
}

.removeButton {
  background: #990f0f;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 18px;
  cursor: pointer;
}

.removeButton:hover {
  color: rgb(255, 255, 255);
}

.containerType {
  margin-top: 20px;
  text-align: center;
}

.containerType h3 {
  margin-bottom: 10px;
  font-size: 18px;
}

.containerOptions {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.option input {
  display: none; /* Oculta o botão de rádio padrão */
}

.iconWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid transparent;
  padding: 10px;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.iconWrapper:hover {
  border-color: #016e0a; /* Cor de destaque ao passar o mouse */

}

input:checked + .iconWrapper {
  border-color: #016e0a; /* Cor de destaque ao selecionar */
  background-color: #eefaef;
}

.icon {
  width: 120px; /* Ajuste o tamanho da imagem */
  height: auto;
}

.option p {
  margin-top: 5px;
  font-size: 14px;
}


@media (max-width: 768px) {
  .modalContent {
    width: 90%;
  }
  .nav {
    flex-direction: column; /* Empilha os itens verticalmente */
    align-items: flex-start; /* Alinha os itens no início */
    gap: 10px; /* Espaçamento vertical entre os itens */
  }

  .navbar,
  .active {
    width: 100%; /* Itens ocupam toda a largura */
    text-align: center; /* Centraliza o texto */
  }

  .navbar {
    font-size: 14px; /* Reduz o tamanho da fonte */
    padding: 8px; /* Ajusta o preenchimento */
  }

  .active {
    font-size: 14px; /* Reduz o tamanho da fonte */
    padding: 8px; /* Ajusta o preenchimento */
  }
}
