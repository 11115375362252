/* Estilos da caixa de conteúdo */
.content {
	margin: 0 auto;
	font-size: 19px;
	width: auto;
	color: #424242;
	background-color: #f5f7f5;
	display: grid;
	border-radius: 10px;
	padding: 15px 25px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Adiciona uma sombra ao redor da borda */
	padding-bottom: 5%;
}


/* Estilos da barra de navegação */
.nav {
	display: flex;
	width: auto;
	align-items: center;
	color: #424242;
	gap: 2%;
	flex-wrap: nowrap; /* Impede que os itens quebrem para a próxima linha */
	padding-bottom: 2%;
}

.navbar {
	padding: 10px;
	border-radius: 10px;
	color: #424242;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #a1a1a14d;
}

.active {
	padding: 10px;
	border-radius: 10px;
	color: #f2f2f2;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #c91a1a;
}

/* Seu arquivo de estilos CSS */
.motivacaoButton {
	float: right;
	/* Define o alinhamento à direita */
	margin-top: 10px;
	/* Define uma margem superior para espaçamento */
	/* Outros estilos personalizados aqui, como cor de fundo, cor do texto, etc. */
}


/* Estilos para layout flexível */
.flex {
	margin-top: 1vw;
	display: flex;
	justify-content: space-between;
	text-align: center;
	color: #424242;
}

/* Estilos para o título de período */
.periodo {
	text-align: center;
	color: #424242;
	font-size: 27px;
	font-weight: 700;
}

.motivacaoContainer {
	display: flex;
	justify-content: space-between;
	align-items: center; /* Opcional, para centralizar verticalmente os elementos */
  }

.historico {
	color: #424242;
	font-size: 20px;
	margin-bottom: 10px;
	padding-bottom: 1%;
	font-weight: 700;
}

.motivacaoButton {
padding-right: 2%;  }

/* Estilos para o formulário */
.formulario {
	background-color: #f0f3f5;
	width: 100%;
	border: 1px dashed #424242;
	font-weight: 600;
	padding-top: 5px;
}

.linha {
	width: 100%;
	font-weight: 600;
	padding-top: 5px;
}

/* Estilos para o sumário */
.sumario {
	display: flex;
	justify-content: space-around;
	margin: 5px 0;
}

.sumario div {
	border-right: 1px solid #424242;
	width: 34%;
}

/* Estilos para a lista */
.lista {
	background-color: #fff;
	height: 160px;
	width: 98%;
	margin: auto;
	margin-left: 10px;
	margin-bottom: 8px;
	overflow-y: auto;
	overflow-x: hidden;
}

.item {
	display: flex;
	justify-content: space-between;
	border-top: 1px solid #d8d8d8;
}

.item div {
	width: 30%;
	font-weight: 400;
	color: #585858;
}

/* Estilos para a barra de rolagem personalizada */
.lista::-webkit-scrollbar-track {
	box-shadow: inset 0 0 3px grey;
	border-radius: 10px;
}

.lista::-webkit-scrollbar-thumb {
	background: #424242;
	border-radius: 10px;
}

.lista::-webkit-scrollbar {
	width: 8px;
}

/* Estilos para o botão de envio */
.submit {
	margin-top: 10px;
}

/* Estilos da caixa com borda e sombra */
.box {
	margin-top: 10px;
	border: 2px solid #424242a2;
	color: #424242;
	padding: 15px;
	border-radius: 5px;
	box-shadow: 0 2px 5px #424242;
	background-color: #f3f3f3;
	font-family: Arial, sans-serif;
	font-size: 16px;
	line-height: 1.5;
}

/* Estilos para o nome */
.name {
	color: #424242;
	margin-right: 5px;
}

/* Estilos para o cabeçalho */
.cabecario {
	margin-top: 10px;
	font-weight: 650;
	font-size: 30px;
	color: #424242;
}

/* Estilos para o segundo botão de envio */
.submit2 {
	display: flex;
	justify-content: end;
	margin-top: -60px;
	margin-right: -5px;
}

.historico_motorista {
	font-size: 1.2rem;
	font-weight: bold;
	margin-bottom: 10px;
}

.historico {
	padding-bottom: 0%;
}

.table {
	border-collapse: collapse;
	margin: 20px 0;
	font-size: 14px;
	text-align: center;
	border: 1px solid #ddd;
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
	/* Adicionando uma pequena sombra para dar profundidade */
	border-radius: 3px;
	/* Característica adicionada do primeiro CSS */
	overflow: hidden;
	/* Característica adicionada do primeiro CSS */
	table-layout: auto;
	/* Ajuste automático das colunas na horizontal */
}

.table td:nth-child(2) {
	white-space: nowrap;
}


.table thead {
	background-color: #f2f2f2;
}

.table th,
.table td {
	padding: 3px 4px;
	font-weight:300;
	border: 1px solid #ddd;
}

/* Estilização do cabeçalho da tabela */
.table thead th {
	padding: 5px 6px;
	border: 1px solid #ddd;
	font-weight: bold;
	background-color: #424242;
	color: #f2f2f2;
}

/* Zebra striping para linhas pares */
.table tbody tr:nth-child(even) {
	background-color: #f5f5f5;
}

/* Efeito de hover nas linhas da tabela */
.table tbody tr:hover {
	background-color: #d5ffcf;
}

/* ... [Seu CSS original aqui] ... */

@media (max-width: 768px) {
    /* Ajuste no contêiner principal para evitar o corte de conteúdo */
    .content {
        margin: 0;
        padding: 10px;
        font-size: 16px; /* Reduz o tamanho da fonte para melhor ajuste */
        box-sizing: border-box; /* Inclui padding no cálculo da largura */
        display: flex;
        flex-direction: column;
        align-items: stretch; /* Estica os filhos para preencher a largura */
    }

    /* Adapta a barra de navegação para telas menores */
    .nav {
        flex-direction:row;
        padding: 2px 0;
    }

    .nav div {
        text-align:center;
        padding: 10px;
        font-size: 16px;
    }

    /* Ajustes nas colunas para evitar sobreposição e garantir legibilidade */
    .columns {
        flex-direction: column;
    }

    .column {

        margin-bottom: 10px; /* Adiciona espaçamento entre as colunas */
    }

    /* Ajustes para o botão e a caixa de informações do motorista */
    .submit2 {
        float: none;
        margin-top: 0;
        width: 100%; /* Ocupa toda a largura disponível */
        text-align: center; /* Centraliza o botão */
    }

    .box {
        order: 1;
    }

    .table {
        width: 100%;
        border: 0; /* Remove bordas da tabela */
		border-radius: 5%;
    }

    .table thead {
        display: none; /* Oculta o cabeçalho da tabela */
		
    }

    .table tbody,
    .table tr {
        display: block; /* Faz cada célula ser um bloco, similar a uma lista */
        width: 100%;
		text-align: left; /* Alinha todo o conteúdo à esquerda */
		background-color: #d5ffcf;
	
    }

	.table tbody tr:first-child td:first-child {
        background-color: #424242; /* Cor de fundo verde */
        color: #f2f2f2; /* Cor de texto clara para contraste */
        border: 1px solid #424242; /* Borda da mesma cor do fundo */
    }

    .table td {
        display: block; /* Faz cada célula ser um bloco, similar a uma lista */
        width: 100%;
		text-align: left; /* Alinha todo o conteúdo à esquerda */
		
		
    }

    .table td:before {
        content: attr(data-label); /* Adiciona o nome do cabeçalho antes de cada célula */
        font-weight: bold;
        display: inline-block; /* Faz o cabeçalho e a célula ficarem na mesma linha */
        width: 90px; /* Largura fixa para os cabeçalhos */
        margin-right: 10px; /* Espaço entre o cabeçalho e o conteúdo da célula */
    }

	.table tbody tr:not(:first-child) {
        display: none;
    }

	.motivacaoContainer {
        flex-direction: column; /* Organiza os elementos em coluna */
    }

    .motivacaoButton {
        float: none;
        margin-top: 0;
        width: 100%; /* Ocupa toda a largura disponível */
        text-align: center; /* Centraliza o texto do botão */
		margin-bottom: 5px;
    }

    .historico {
        margin-bottom: 0px; /* Espaçamento entre o título e o botão */
    }
}
