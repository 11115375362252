
/* Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
  }
  
  /* Cores principais */
  :root {
    --primaryColor: #065986;
    --backgroundLight: #ffffff;
    --textColor: #333;
  }
  

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-image: url("../../img/naviohd.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  
  /* Caixa de login */
  .loginBox {
    background-color: #ffffff;
    padding: 2em;
    border-radius: 8px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6);
    text-align: center;
    width: 100%;
    max-width: 400px;
  }
  
  /* Logotipo */
  .logo {
    width: 150px;
    margin-bottom: 1.5em;
  }
  
  /* Estilo do formulário */
  .form {
    display: flex;
    flex-direction: column;
  }
  
  /* Grupo de entrada */
  .inputGroup {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .inputGroup label {
    font-size: 0.9em;
    margin-bottom: 0.1em;
    color: var(--textColor);
  }
  
  .inputGroup input {
    padding: 0.75em;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
    outline: none;
  }
  
  .inputGroup input:focus {
    border-color: var(--primaryColor);
  }
  
  /* Ícone para mostrar/esconder senha */
  .togglePassword {
    font-size: 0.8em;
    color: var(--primaryColor);
    cursor: pointer;
    margin-top: 0.5em;
  }
  
  /* Mensagem de erro */
  .errorMsg {
    color: red;
    font-size: 0.9em;
    margin-bottom: 1em;
  }
  
  /* Botão de login aprimorado */
.loginButton {
    background: linear-gradient(135deg, #09446A, #0083CB);
    color: #fff;
    border: none;
    padding: 0.25em;
    font-size: 1em;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .loginButton:hover {
    background: linear-gradient(135deg, #00296B, #065986);
    transform: scale(1.05);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .loginButton:active {
    transform: scale(0.98);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  }
  
  /* Link de registro */
  .registerLink {
    font-size: 0.9em;
    margin-top: 1em;
    color: var(--primaryColor);
  }
  
  .registerLink a {
    color: var(--primaryColor);
    font-weight: bold;
    text-decoration: none;
  }
  
  .registerLink a:hover {
    text-decoration: underline;
  }

  .modal {
    width: 400px; /* Reduza para o tamanho desejado */
    max-width: 90%; /* Responsividade para telas menores */
    margin: auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  /* Responsividade para telas menores */
  @media (max-width: 480px) {
    .container {
      padding: 1em;
    }
    
    .loginBox {
      width: 100%;
    }
  }
  
