.container {
    z-index: -1;
    margin: 25px 20px 0 80px;
    
/* From https://css.glass 
    background: #ffffff1c;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2.2px);
    -webkit-backdrop-filter: blur(2.2px);*/
    border: 1px solid #00000007
}