/* Geral */
.container {
	padding: 20px;
  }
  
  .content {
	margin: 0 auto;
	font-size: 19px;
	width: auto;
	color: #424242;
	background-color: #f5f7f5;
	display: grid;
	border-radius: 10px;
	padding: 15px 25px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Adiciona uma sombra ao redor da borda */
	padding-bottom: 5%;
}
  
  .nav {
	display: flex;
	width: auto;
	align-items: center;
	color: #424242;
	gap: 2%;
	flex-wrap: nowrap; /* Impede que os itens quebrem para a próxima linha */
	padding-bottom: 2%;
}

.navbar {
	padding: 10px;
	border-radius: 10px;
	color: #424242;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #a1a1a14d;
}
  
.active {
	padding: 10px;
	border-radius: 10px;
	color: #f2f2f2;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #c91a1a;
}
  
  .radiofluxo {
	display: flex;
	align-items: center;
	gap: 20px;
  }
  
  .control {
	display: flex;
	gap: 15px;
  }
  
  .input {
	display: flex;
	align-items: center;
	font-size: 1rem;
  }
  
  .fieldset {
	margin-bottom: 10px;
	padding-right: 15px;
	font-size: 16px;
	padding-left: 15px;
	padding-bottom: 20px;
	border: 1px solid #5a0404;
	border-radius: 8px;
  }

  .fieldset_alt {
	padding-right: 15px;
	padding-left: 15px;

	border: 1px solid #5a0404;
	border-radius: 8px;
  }
  
  .legend {
	font-weight: bold;
	padding: 0 10px;
	margin-bottom: 10px;
	font-size: 1.2rem;
	color: #333;
  }
  
  .twoColumnGrid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 15px;
  }
  
  /* Cartão de Dados do Motorista */
  .card {
	background-color: #fff;
	padding: 15px;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .cabecario {
	margin-bottom: 10px;
  }
  
  /* Formulário de Seleção */
  .form_control, .form_controldoc, .form_controllab {
	margin-bottom: 15px;
  }
  
  .select, select {
	width: 100%;
	padding: 8px;
	font-size: 1rem;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
  }

  .font {
	font-size: 15px;
	font-weight: bold;
	padding-top: 12px;
  }

  .font select {
	padding: 7px;
  }
  
  /* Input Container */
  .placas {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 15px;
	margin-bottom: 15px;
  }
  
  .placaContainer {
	display: flex;
	flex-direction: column;
  }
  
  .placaContainer label {
	font-weight: bold;
	margin-bottom: 2px;
  }
  
  .placaContainer input {
	padding: 5px;
	font-size: 1rem;
	border: 1px solid #ccc;
	border-radius: 4px;
  }
  
  /* Radio Inputs */
  .radio {
	margin-bottom: 15px;
  }
  
  .radio .control {
	display: flex;
	gap: 15px;
  }
  
  .radio .input {
	display: flex;
	align-items: center;
  }
  
  /* Big Bags e Container */
  .bigbags {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 15px;
	margin-bottom: 15px;
  }
  
  .inputWrapper {
	display: flex;
	flex-direction: column;
  }
  
  .inputWrapper span {
	font-weight: bold;
	margin-bottom: 5px;
  }
  
  .quantidade, .peso_bigbag {
	display: flex;
	flex-direction: column;
  }
  
  /* Saldos */
  .form_controlsaldo {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	border-radius: 4px;
	color: #fff;
	font-weight: bold;
	margin-bottom: 15px;
  }
  
  .label {
	margin-right: 5px;
  }
  
  .value {
	flex-grow: 1;
	text-align: right;
  }
  
  /* Transportadora */
  .transportadora {
	margin-bottom: 15px;
  }
  
  /* Botão de Cadastro */
  .button {
	display: flex;
	justify-content: center;
	margin-top: 20px;
  }
  
  .button .submit-button {
	background-color: #007bff;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 4px;
	font-size: 1rem;
	cursor: pointer;
  }
  
  .button .submit-button:hover {
	background-color: #0056b3;
  }

  .inputField {
	width: 100%;
	padding: 1px;
	font-size: 1rem;
	border: 1px solid #ccc;
	border-radius: 5px;
	box-sizing: border-box;
	margin-top: 1px;
	transition: border-color 0.3s ease-in-out;
  }
  
  .inputField:focus {
	border-color: #007bff; /* Cor de destaque ao focar */
	outline: none; /* Remove a borda azul padrão */
  }
  
  .inputField::placeholder {
	color: #999; /* Cor do texto do placeholder */
	font-style: italic;
  }
  
  .inputWrapper {
	margin-bottom: 2px;
  }
  
  
  /* Responsividade */
  @media (max-width: 768px) {
	.placas {
	  grid-template-columns: 1fr;
	}
  
	.bigbags {
	  grid-template-columns: 1fr;
	}
  
	.radiofluxo {
	  justify-content: flex-start;
	}
  }
  