.content {
	margin: 0 auto;
	font-size: 19px;
	width: auto;
	color: #424242;
	background-color: #f5f5f5;
	display: grid;
	border-radius: 10px;
	padding: 15px 25px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Adiciona uma sombra ao redor da borda */
	padding-bottom: 5%;
}
  
/* Estilos da barra de navegação */
.nav {
	display: flex;
	width: auto;
	align-items: center;
	color: #424242;
	gap: 2%;
	flex-wrap: nowrap; /* Impede que os itens quebrem para a próxima linha */
	padding-bottom: 2%;
}

.navbar {
	padding: 10px;
	border-radius: 10px;
	color: #424242;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #a1a1a14d;
}

.active {
	padding: 10px;
	border-radius: 10px;
	color: #f2f2f2;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #c91a1a;
}
.tabContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #ddd;
}

.tabButton {
  padding: 5px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  outline: none;
}

.tabButton:hover {
  background-color: #0057b300;
}

.active {
  color: white;
  border-bottom: 3px solid #0056b3;
}

.radioGroup {
  display: flex;
  justify-content: center; /* Centraliza os botões horizontalmente */
  gap: 20px; /* Espaçamento entre os botões */
  margin-bottom: 20px; /* Espaçamento inferior */
}

.radioGroup label {
  display: flex;
  align-items: center; /* Alinha o texto e o botão verticalmente */
  font-size: 16px;
  cursor: pointer;
}

.checklist {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.checklist h2 {
  text-align: center;
  margin-bottom: 20px;
}

.checklist label {
  display: block;
  margin-bottom: 10px;
}

.checklist input[type="text"],
.checklist input[type="datetime-local"],
.checklist textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.checklist button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.checklist button:hover {
  background-color: #0056b3;
  color: white;
}

.photoList ul {
  list-style: none;
  padding: 0;
}

.photoList li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.photoList li:hover {
  background-color: #f0f0f0;
}

.thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 10px;
}

.removeButton {
  background: #990f0f;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 18px;
  cursor: pointer;
}

.removeButton:hover {
  color: rgb(255, 255, 255);
}

/* Estilos gerais da tabela */
.table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 14px;
  text-align: center;
  border: 1px solid #ddd;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1); /* Adicionando uma pequena sombra para dar profundidade */
  border-radius: 3px; /* Característica adicionada do primeiro CSS */
  overflow: hidden;   /* Característica adicionada do primeiro CSS */
}

/* Cabeçalho da tabela */
.table thead {
  background-color: #c91a1a; /* Cor de fundo do cabeçalho */
  color: #ffffff; /* Cor do texto do cabeçalho */
}

.table thead th {
  padding: 8px 12px;
  border: 1px solid #ddd;
  font-weight: bold;
  background-color: #c91a1a;
  color: #f2f2f2;
}

/* Corpo da tabela */
.table tbody td {
  padding: 8px 8px; /* Espaçamento interno nas células */
  border-top: 1px solid #ddd; /* Linha sutil entre as linhas */
}

/* Hover effect */
.table tbody tr:hover {
  background-color: #f5f5f5; /* Cor de fundo ao passar o mouse */
}

.excelButton {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.excelButton:hover {
  background-color: #218838;
}

.error {
  color: red;
  font-weight: bold;
}

.excelIcon {
  font-size: 18px;
}

/* Cards */
.cards {
  display: flex;
padding-top: 1%;
  gap: 2%;
  align-items: flex-end;
}

.card {
  border: 3px solid var(--secondary-color);
display:flexbox;
text-align-last: center;
flex: auto; 
  border-radius: 12px;
  background-color: var(--main-color);
  color: var(--white-color);
  font-family: roboto;
  padding: 10px;
  font-weight: 380;
  justify-content: center;
}

.total {
  border: 0px solid #153240a2;
font-size: 22px;
font-weight: bold;
  color: #ffffff;
  font-family: roboto;
    
}

.count {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
}

.highlight {
background-color: #ffff99; /* Cor amarela suave para destaque */
border: 2px solid #ffcc00; /* Bordar com amarelo mais forte */
}

@media (max-width: 768px) {
  .modalContent {
    width: 90%;
  }
}
