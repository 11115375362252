.content {
	margin: 0 auto;
	font-size: 19px;
	width: auto;
	color: #424242;
	background-color: #f5f7f5;
	display: grid;
	border-radius: 10px;
	padding: 15px 25px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Adiciona uma sombra ao redor da borda */
	padding-bottom: 5%;
}


/* Estilos da barra de navegação */
.nav {
	display: flex;
	width: auto;
	align-items: center;
	color: #424242;
	gap: 2%;
	flex-wrap: nowrap; /* Impede que os itens quebrem para a próxima linha */
	padding-bottom: 2%;
}

.navbar {
	padding: 10px;
	border-radius: 10px;
	color: #424242;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #a1a1a14d;
}

.active {
	padding: 10px;
	border-radius: 10px;
	color: #f2f2f2;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #c91a1a;
}


.gfbox {
  display:flex;
  flex: 1; /* Distribui o espaço igualmente entre os elementos */
  justify-content: center; /* Centralize horizontalmente */
  margin: 0 10%; /* Adicione margens para espaçamento entre os elementos */
  padding: 15px;
  transition: transform 0.2s;
  align-items: center;
}

.DI_BL, .NOME_REDUZIDO {
    text-align: center;
    font-weight: 600;
    color: #333;
    font-size: 18px;
    margin-top: 10px;
}

.gftitle {
  padding-top: 2%;
  text-align: center;
  font-size: 24px;
  color: #222121;
  margin-bottom: 20px;
}

.gftitlesecond{
  padding-top: 15%;
  text-align: center;
  font-size: 24px;
  color: #222121;
  margin-bottom: 20px;
}

.chart-container {
  position: relative;
  width: 80vw;
  margin: 0 auto;
}

.chart-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #424242;
  margin-bottom: 20px;
}

.chart-label {
  font-weight: 600;
  color: #333; /* Altere a cor do texto para maior legibilidade */
  margin-bottom: 10px;
}

.bar-horizontal {
  display: flex;
  justify-content: flex-start; /* Mude para flex-start para barras horizontais */
  align-items: center;
  background-color: #75aaff; /* Cor de fundo da barra */
  border: 1px solid #4287f5; /* Cor da borda da barra */
  margin-bottom: 10px; /* Espaçamento entre as barras */
  padding: 10px; /* Espaçamento interno da barra */
}

.bar-progress {
  width: var(--saldo); /* Agora usa a variável CSS --saldo para a largura */
  height: 30px; /* Altura da barra */
  background-color: #424242; /* Cor da barra de progresso */
  transition: width 0.3s ease-in-out; /* Transição suave da largura */
}

/* Estilos para o componente GraficoPercent (separado em outro arquivo CSS) */

.gftitle {
  text-align: center;
  font-size: 24px;
  color: #222121;
  margin-bottom: 20px;
}

.grbar {
  width: var(--saldo); /* Agora usa a variável CSS --saldo para a largura */
  height: 60%;
  background-color: #424242; /* Cor da barra de progresso */
  border-top: 3px solid #3c8031;
  border-radius: 3px;
}

.gfcoluns {
  display:flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Usar space-between para separar os elementos */
  
}

.centralizar {
  display: flex; /* Use flexbox para alinhar vertical e horizontalmente */
  justify-content: center; /* Centralize horizontalmente */
  padding-bottom: 5%;
}

.gfcolum {
  display:inline-block;
  text-align-last: center;
  flex: 1; /* Distribui o espaço igualmente entre os elementos */
  margin: 0 10px; /* Adicione margens para espaçamento entre os elementos */
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  background-color: #fff;
  cursor: pointer;
  position: relative;
}

.gfcolumgeral {
  
  display:inline-block;
  text-align-last: center;
  flex: 1; /* Distribui o espaço igualmente entre os elementos */
  margin: 0 10px; /* Adicione margens para espaçamento entre os elementos */
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  background-color: #fff;
  cursor: pointer;
  position: relative;
}

.difgfcolumgeral {
  
  display:inline-block;
  text-align-last: center;
  flex: 1; /* Distribui o espaço igualmente entre os elementos */
  margin: 0 10px; /* Adicione margens para espaçamento entre os elementos */
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  background-color: #fff;
  cursor: pointer;
  position: relative;
}


.gfcolum:hover {
  
  transform: translateY(-5px);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.grpercent {
  text-align: center;
  font-weight: 600;
  color: #333;
  font-size: 20px;
  margin-bottom: 10px;
  position: relative;
  z-index: 2;
}

.grbackbar {
  width: 100%;
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.grblue {
  width: 0%;
  width: var(--saldo);
  height: 100%;
  background-color: #007acc;
  border-radius: 5px;
  transition: width 0.5s;
}

.grred {
  width: 0%;
  width: var(--saldo);
  height: 100%;
  background-color: #ff4040;
  border-radius: 5px;
  transition: width 0.5s;
}

.grdesc {
  text-align: justify;
  font-size: 16px;
  color: #222121;
}

.linha {
  height: 1px;
  background: linear-gradient(to right, #ccc, #333, #ccc);
  margin: 15px 0;
}

.manifestado {
  text-align: justify;
  line-height: 150%;
  font-size: 16px;
  color: #222121;
  margin-top: 10px;
}

.manifestado a {
  color: #007acc;
  text-decoration: underline;
  transition: color 0.2s;
}

.manifestado a:hover {
  color: #005b99;
}

/* Estilos para os botões Moega e Balança */
.buttons {
  padding-top: 1%;
  display:inline-flex;
  justify-content: center;
}

.button {
  /* Seus estilos normais */
  margin: 2%;
  width: 15%;
  background-color: #424242;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.activeButton {
  /* Estilos para o botão selecionado */
  background-color: #424242; /* Cor de destaque */
  color: #fff; /* Cor do texto */
}

.button:hover {
  /* Estilos quando o cursor está sobre o botão */
  background-color: #424242; /* Cor de destaque */
  color: #fff; /* Cor do texto */
}
