/* Estilos da caixa de conteúdo */
.content {
	margin: 0 auto;
	font-size: 19px;
	width: auto;
	color: #424242;
	background-color: #f5f7f5;
	display: grid;
	border-radius: 10px;
	padding: 15px 25px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Adiciona uma sombra ao redor da borda */
	padding-bottom: 5%;
}
  
 /* Estilos da barra de navegação */
.nav {
	display: flex;
	width: auto;
	align-items: center;
	color: #424242;
	gap: 2%;
	flex-wrap: nowrap; /* Impede que os itens quebrem para a próxima linha */
	padding-bottom: 2%;
}

.navbar {
	padding: 10px;
	border-radius: 10px;
	color: #424242;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #a1a1a14d;
}

.active {
	padding: 10px;
	border-radius: 10px;
	color: #f2f2f2;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #c91a1a;
}
  
  .flex div {    
	padding-left: 15px;
	display: inline-block;
	padding-bottom: 5px;
  }
  
  .cavalo {
	width: 200px;
	margin-top: -10px;
  }
  
  /* Responsividade para dispositivos móveis */
  @media (max-width: 768px) {
	.content {
	  font-size: 16px; /* Reduz o tamanho da fonte para melhorar a legibilidade */
	  padding: 10px; /* Reduz o padding para aumentar o espaço disponível */
	}
  
	.nav {
	  flex-direction: column; /* Empilha os itens do menu na vertical */
	  padding: 5px; /* Reduz o padding para economizar espaço */
	  width: 100%; /* Faz o nav usar toda a largura disponível */
	}
  
	.nav div {
	  padding: 5px; /* Aumenta o tamanho da área clicável para toque */
	  width: 100%; /* Faz com que cada opção do menu ocupe toda a largura */
	}
  
	.flex div {
	  padding-left: 5px; /* Ajusta o padding para elementos em linha */
	  padding-bottom: 3px;
	  width: 100%; /* Faz com que os elementos em linha ocupem toda a largura */
	}
  
	.cavalo {
	  width: 100%; /* Faz com que o input ocupe toda a largura disponível */
	  margin-top: 5px; /* Ajusta a margem superior */
	}
  }
  