.modal {
	margin: 0 auto;
	width: 100%;
	background-color: #F9FAFB;
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	padding:  15px;
	color: #424242;
}

/* Estilos da caixa de conteúdo */
.content {
	margin: 0 auto;
	font-size: 19px;
	width: auto;
	color: #424242;
	background-color: #f5f7f5;
	display: grid;
	border-radius: 10px;
	padding: 15px 25px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Adiciona uma sombra ao redor da borda */
	padding-bottom: 5%;
}


/* Estilos da barra de navegação */
.nav {
	display: flex;
	width: auto;
	align-items: center;
	color: #424242;
	gap: 2%;
	flex-wrap: nowrap; /* Impede que os itens quebrem para a próxima linha */
	padding-bottom: 2%;
}

.navbar {
	padding: 10px;
	border-radius: 10px;
	color: #424242;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #a1a1a14d;
}

.active {
	padding: 10px;
	border-radius: 10px;
	color: #f2f2f2;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #c91a1a;
}

/*-----------------------------------------------*/

.tableWrapper {
	margin: 20px 0;
	width: 100%;
	overflow-x: auto; /* Permite rolar horizontalmente se a tabela for maior que o contêiner */
  }
  
  .table {
	width: 100%; /* Tabela ocupa toda a largura disponível */
	border-collapse: collapse; /* Remove espaçamento entre bordas das células */
	font-family: Arial, sans-serif; /* Define uma fonte legível */
  }
  
  .table th, .table td {
	border: 1px solid #ddd; /* Adiciona uma borda leve entre as células */
	padding: 12px; /* Espaçamento interno nas células */
	text-align: center; /* Centraliza o texto nas células */
	font-size: 16px; /* Tamanho da fonte para legibilidade */
  }
  
  .table th {
	background-color: #f2f2f2; /* Fundo mais claro para o cabeçalho */
	font-weight: bold; /* Negrito para os títulos */
	color: #424242; /* Cor escura para o texto do cabeçalho */
  }
  
  .table td {
	background-color: #ffffff; /* Fundo branco nas células */
	color: #333; /* Texto em cinza escuro */
  }
  
  .table tr:nth-child(even) {
	background-color: #f9f9f9; /* Fundo alternado para linhas pares */
  }
  
  .table tr:hover {
	background-color: #f1f1f1; /* Cor de fundo quando o usuário passar o mouse */
  }

.actions {
	display: flex;
	justify-content: space-between; /* Espaçamento entre os botões */
	margin-top: 20px;
	padding: 10px 15px;
	background-color: #f5f7f5; /* Fundo mais claro */
	border-radius: 5px;
  }
  
  .actionss {
	width: 45%; /* Deixa os botões com a mesma largura */
	padding: 10px 20px;
	font-weight: bold;
	color: white;
	border-radius: 5px;
	cursor: pointer;
	border: none;
	transition: background-color 0.3s ease; /* Suaviza a transição de cor */
  }
  
  .actionss:hover {
	background-color: #555; /* Cor de fundo ao passar o mouse */
  }
  
  .actionss:first-child {
	background-color: #c91a1a; /* Cor de fundo para o botão 'Cancelar' */
  }
  
  .actionss:first-child:hover {
	background-color: #9c0b0b; /* Cor ao passar o mouse no botão 'Cancelar' */
  }
  
  .actionss:last-child {
	background-color: #0b9c48; /* Cor de fundo para o botão 'Confirmar' */
  }
  
  .actionss:last-child:hover {
	background-color: #087a37; /* Cor ao passar o mouse no botão 'Confirmar' */
  }
  

.flex {
	display: flex;
	justify-content: space-between;
    width: 95%;
    margin:1.5% auto ;
}

.flex {
	border: #c91a1a;
}

.periodo {
	text-align: center;
	font-size: 27px;
	font-weight: 700;
    margin: auto 0;
}

.data {
	font-weight: 600;
	font-size: 22px;
	letter-spacing: -1px;
	margin-top: -8px;
	border-top: 2px solid #d9d9d9;
}

.nota {
	border: 1px dashed #424242;
    padding: 5px 10px;
    background-color: #e8ecef;
    color: #3b3d3f;
	margin: auto;
	margin-top: 20px;
	font-size: 14px;
}

.gera_nota {
	display: flex;
	align-items: baseline;
	justify-content: center;
	margin-bottom: -5px;
}

.obs_nota_status_def{
	color: auto;
}

.obs_nota_status_3, .obs_nota_status_5{
	color: #ff0000;
}

.obs_nota_status_4{
	color: #00ff00;
}

.motorista{
    width: 90%;
	border-radius: 20px;
    padding: 5px 6px;
	margin-top: 1%;
}.motorista b{
    color: #424242;
    font-weight: 600;
}

.motoristaid{
    width: 100%;
	border-radius: 20px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    padding: 5px 10px;
	margin-top: 1%;
    background-color: #edf0ed;
    color: #3b3d3f;
}.motoristaid b{
    color: #424242;
    font-weight: 600;
}

.problemas{
    width: 100%;
	border-radius: 20px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    
	margin-top: 1%;
    background-color: #edf0ed;
    color: #3b3d3f;
}.problemas b{
    color: #424242;
    font-weight: 600;
}

.inputbox{
    width: 33%;
	padding: 1px 5px;
	font-size: 16px;
}.flex input{
	font-size: 18px;
	width: 100%;
	padding: 3px 5px;
	transition: 0.1s;
	margin-top: 3px;
	font-size: 15px;
	border-radius: 3px;
	border: 1px solid #ccc;

}.inputbox input:focus {
	border: 1px solid #396777;
	outline: 2px solid #424242;
}.inputbox_ticket{
    width: 33%;
	padding: 1px 6px;
}



.button_as_text {
	background: none;       /* Remove o fundo do botão */
	border: none;           /* Remove a borda do botão */
	padding: 0;             /* Remove o preenchimento do botão */
	color: #024207;           /* Define a cor do texto para preto */
	cursor: pointer;        /* Muda o cursor para indicar que o elemento é clicável */
	text-align: left; 
	font-size: inherit;
	font-family: inherit;
  }
  .button_as_text:hover {
	background-color: #00ff0000;
	
  }

.inputbox_pesoprovisorio {
	color: black; /* Define a cor do texto para preto */
	/* Outros estilos podem ser adicionados aqui se necessário */
  }

.inputbox_pesoprovisorio{
    width: 20%;
	padding: 0px 9px;
}
.inputbox_dataprovisorio{
    width: 32%;
	padding: 0px 6px;
}

/* Style.css (or wherever your styles are defined) */

.reportarProblemaButton {
	background-color: #f44336; /* Red color */
	color: white;
	border: none;
	padding: 1px 1px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 10px 20px;
	transition-duration: 0.4s;
	cursor: pointer;
	border-radius: 5px;
  }
  
  .reportarProblemaButton:hover {
	background-color: white;
	color: #f44336;
	border: 1px solid #f44336;
  }

.inputbox_pesagem{
	width: 33%;
	padding: 1px 10px;

}

.textbox{
    width: 100%;
}.textbox textarea{
	font-size: 16px;
	width: 100%;
	padding: 3px 5px;
	transition: 0.1s;
	margin-top: 3px;
	font-size: 15px;
	border-radius: 3px;
	border: 1px solid #ccc;
}.textbox textarea:focus {
	border: 1px solid #8ad4ee;
	outline: 2px solid #424242;
}

.navio{
	font-size: 25px;
	font-weight: 700;
}

.finalizar{
	background-color: #c91a1a;
	color: #ffffff;
	border: none;
	padding: 8px 28px;
	font-weight: 500;
	border-radius: 3px;
	cursor: pointer;
}

.finalizar:hover{
	background-color: #f03232;
}

/**/

.selectbox{
	width: 90%;
	margin: 1.5% auto ;
}.selectbox select{
	font-size: 16px;
	width: 100%;
	padding: 3px 5px;
	transition: 0.1s;
	margin-top: 3px;
	font-size: 15px;
	border-radius: 3px;
	border: 1px solid #ccc;
}.selectbox select:focus {
	border: 1px solid #8ad4ee;
	outline: 2px solid #424242;
}


.center {
	padding: 50px 0;
	text-align: center;
	font-size: 22px;
	font-weight: 600;
}.center div{
	font-size: 18px;
	margin-top: 15px;
	color: #7a7d85;
	font-weight: 500;
}

.navio {
	font-size: 22px;
	font-weight: 700;
	margin: auto 0;
}

button {
	width: 45%;
	padding: 10px 20px;
	font-weight: bold;
	color: white;
	border-radius: 5px;
	cursor: pointer;
	border: none;
  }
  
  button.confirmar {
	background-color: #0b9c48;
  }
  
  button.confirmar:hover {
	background-color: #087a37;
  }
  
  button.cancelar {
	background-color: #c91a1a;
  }
  
  button.cancelar:hover {
	background-color: #9c0b0b;
  }