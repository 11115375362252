.dialogContentContainer {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap onto the next line */
  justify-content: space-between;
  padding: 20px;
}

.column {
  flex: 1 1 50%; /* Allows each column to take up half of the container width */
  min-width: 250px; /* Ensures that columns don't get too narrow */
  padding: 10px; /* Space inside each column */
}

/* AgendamentoPesagem.module.css */
.iconValidated {
  color: green;
  vertical-align: middle;
  margin-right: 8px; /* Adds spacing between icon and text */
}

.listItem {
  display: flex;
  align-items: center; /* Ensures icon and text are aligned */
  margin-bottom: 4px; /* Adds spacing between items */
}




@media (max-width: 768px) {
  .column {
      flex: 1 1 100%; /* Stacks the columns vertically on smaller screens */
  }
}
