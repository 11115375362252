/*CSS DO MODAL */

.modal {
	margin: 0 auto;
	width: 100%;
	background-color: #f5f7f5;
	border-radius: 5px;
	padding:  25px;
	color: #424242;
}

/* Estilos da caixa de conteúdo */
.content {
	margin: 0 auto;
	font-size: 19px;
	width: auto;
	color: #424242;
	background-color: #f5f7f5;
	display: grid;
	border-radius: 10px;
	padding: 15px 25px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Adiciona uma sombra ao redor da borda */
	padding-bottom: 5%;
}


/* Estilos da barra de navegação */
.nav {
	display: flex;
	width: auto;
	align-items: center;
	color: #424242;
	gap: 2%;
	flex-wrap: nowrap; /* Impede que os itens quebrem para a próxima linha */
	padding-bottom: 5%;
}

.navbar {
	padding: 10px;
	border-radius: 10px;
	color: #424242;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #a1a1a14d;
}

.active {
	padding: 10px;
	border-radius: 10px;
	color: #f2f2f2;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #c91a1a;
}

/*-----------------------------------------------*/


.center{
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 3% auto;
	margin-top: 4%;
}

.flex {
	display: flex;
	justify-content: space-between;
    width: 100%;
    margin:0 auto;
}

.status{
	background-color: #424242;
	color: #ffffff;
	font-size: 22px;
	font-weight: 600;
	padding: 4px 10px;
	text-align: center;
	border-radius: 3px;
}

.detalhebox{
	margin: 1% auto ;
	font-size: 19px;
	color: #000;
}.detalhebox b{
	color: #424242;
	font-size: 18px;
}

.finalizar{
	background-color: #424242;
	color: #ffffff;
	border: none;
	padding: 8px 28px;
	font-weight: 500;
	border-radius: 3px;
	margin: auto;
	margin-top: 25px;
	cursor: pointer;
}.finalizar:hover{
	background-color: #f03232;
}



