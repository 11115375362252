/* Fundo escuro que cobre toda a tela */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Cor de fundo escura com opacidade */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Garante que o modal apareça sobre outros elementos */
}

/* Estilo do modal */
.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px; /* Máxima largura do modal */
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* Garante que o conteúdo do modal apareça sobre o fundo escuro */
}

/* Estilo do conteúdo dentro do modal */
.content {
  padding: 20px;
}

button {
  margin: 10px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}



