/* Reset default margin and padding for all elements */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
  }
  
  /* General styles for common elements */
  body {
	font-family: Arial, sans-serif;
	background-color: #F9FAFB;
	color: #424242;
	overflow-x: hidden;
  }
  
  .container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 15px;
  }
  
  /* Header styles */
  .header {
	background-color: #424242;
	color: #ffffff;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 24px;
	font-weight: bold;
  }
  
  .header a {
	text-decoration: none;
	color: #eeeeee;
	margin-left: 20px;
  }
  
/* Estilos da caixa de conteúdo */
.content {
	margin: 0 auto;
	font-size: 19px;
	width: auto;
	color: #424242;
	background-color: #f5f7f5;
	display: grid;
	border-radius: 10px;
	padding: 15px 25px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Adiciona uma sombra ao redor da borda */
	padding-bottom: 5%;
}


/* Estilos da barra de navegação */
.nav {
	display: flex;
	width: auto;
	align-items: center;
	color: #424242;
	gap: 2%;
	flex-wrap: nowrap; /* Impede que os itens quebrem para a próxima linha */
	padding-bottom: 2%;
}

.navbar {
	padding: 10px;
	border-radius: 10px;
	color: #424242;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #a1a1a14d;
}

.active {
	padding: 10px;
	border-radius: 10px;
	color: #f2f2f2;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #c91a1a;
}
  
  /* Navigation Header styles */
  .navio {
	font-size: 25px;
	font-weight: 700;
	color: #424242;
	margin-bottom: 20px;
  }
  
  .notform {
	text-align: center;
	font-size: 29px;
	padding: 150px;
	color: #424242;
  }
  
  /* Table styles */
  .table {
	margin-top: 5%;
  }
  
  /* Custom styles for the dropdown */
  .select-container {
	position: relative;
	display: inline-block;
	margin-right: 10px; /* Adjust margin as needed */
  }
  
  .select-container select {
	appearance: none;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 8px 12px;
	font-size: 16px;
	width: 100%;
	cursor: pointer;
  }
  
  /* Style the dropdown arrow */
  .select-container select::-ms-expand,
  .select-container select::-ms-check {
	display: none;
  }
  
  .select-container select::-webkit-select-placeholder {
	color: #999; /* Placeholder text color */
  }
  
  /* Custom styles for the "Gerar Relatório" button */
  .btnRelatorio {
	background-color: #424242;
	color: #ffffff;
	border: none;
	border-radius: 4px;
	padding: 10px 20px;
	font-size: 16px;
	cursor: pointer;
	transition: background-color 0.3s ease;
  }
  
  .btnRelatorio:hover {
	background-color: #306125; /* Change color on hover */
  }
  
  /* Style for the dropdown container */
  .dropdownContainer {
	margin-top: 20px; /* Adjust margin as needed */
	display: flex;
	align-items: center;
  }
  
  .navioDropdown {
	appearance: none;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 8px 12px;
	font-size: 16px;
	width: 100%;
	cursor: pointer;
	margin-right: 10px; /* Adjust margin as needed */
  }
  
  /* Style the dropdown arrow */
  .navioDropdown::-ms-expand,
  .navioDropdown::-ms-check {
	display: none;
  }
  
  .navioDropdown::-webkit-select-placeholder {
	color: #999; /* Placeholder text color */
  }
  
  /* Style for the table container */
  .table_container {
	display: flex;
	flex-wrap: wrap; /* Os itens da tabela podem quebrar para a próxima linha quando necessário */
	gap: 20px; /* Espaçamento entre os itens da tabela */
	justify-content: center;
  }
  
  .table_item {
	flex: 0 0 calc(33.33% - 20px);
	border: 1px solid #ccc;
	padding: 15px;
	cursor: pointer;
	transition: background-color 0.2s ease, transform 0.2s ease;
	background-color: #f5f5f5;
	text-align: center;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra leve */
  }
  
  /* Estilo para table items on hover */
  .table_item:hover {
	background-color: #424242;
	color: #ffffff;
	transform: scale(1.05);
  }
  
  /* Style for the text inside the table items */
  .detalheNavio {
	white-space: nowrap;
	text-overflow: ellipsis;
  }
  
  .table_outer_container {
	width: 100%;
	display: flex;
	justify-content: center;
  }
  
  /* Style for the icon */
  .icone {
	font-size: 24px;
	margin-right: 8px;
	vertical-align: middle;
  }

  
  