.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  position: relative;
  padding: 20px;
  padding-bottom: 60px; /* Espaço extra para o botão */
  background-color: #fff;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
}

.formGroup {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.formGroup label {
  width: 80px; /* Ajuste conforme necessário */
  margin-right: 10px;
}

.inputField {
  flex: 1;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

input[type="text"] {
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
}


@keyframes fadeIn {
  from { opacity: 0; transform: scale(0.9); }
  to { opacity: 1; transform: scale(1); }
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #333;
}

.inputField {
  width: 100%;
  padding: 8px;
  margin: 8px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.permissionTitle {
  padding-top: 5px;
}

.radioButtons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 10px;
}

.checkboxOption {
  display: flex;
  align-items: center;
}

.checkboxOption input[type="checkbox"] {
  margin-right: 8px;
}

.checkboxOption label {
  font-size: 14px;
  color: #555;
}

.saveButton {
  padding-top: 7px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #4CAF50;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.saveButton:hover {
  background-color: #45a049;
}

.radioOption {
  display: flex;
  align-items: center;
}

.radioOption input[type="radio"] {
  margin-right: 8px;
}

.radioOption label {
  font-size: 14px;
  color: #555;
}

h3 {
  margin-top: 0;
  font-size: 1.5rem;
  color: #333;
}

h4 {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 10px;
}
