/* Estilos da caixa de conteúdo */
.content {
	margin: 0 auto;
	font-size: 19px;
	width: auto;
	color: #424242;
	background-color: #f5f7f5;
	display: grid;
	border-radius: 10px;
	padding: 15px 25px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Adiciona uma sombra ao redor da borda */
	padding-bottom: 5%;
}


/* Estilos da barra de navegação */
.nav {
	display: flex;
	width: auto;
	align-items: center;
	color: #424242;
	gap: 2%;
	flex-wrap: nowrap; /* Impede que os itens quebrem para a próxima linha */
	padding-bottom: 2%;
}

.navbar {
	padding: 10px;
	border-radius: 10px;
	color: #424242;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #a1a1a14d;
}

.active {
	padding: 10px;
	border-radius: 10px;
	color: #f2f2f2;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #c91a1a;
}

/*-----------------------------------------------*/

.table {
	margin-top: 1vh;
}

.sumario {
	color: #8A909B;
	background-color: #F9FAFB;
	display: flex;
	justify-content: space-around;
	border-radius: 5px 5px 0 0;
	padding: 10px 0 ;
	border-bottom: 1px solid #E9EBEE;
	justify-items: center;
}

.sumario div {
	font-family: "Titillium Web", sans-serif;
	font-size: 18px;
	cursor: default;
	width: 30%;
	text-align: center;
}

.table_item {	
	background-color: #ffffff;
	color: #2B313F;
	display: flex;
	justify-content: space-around;
	justify-items: center;
	border-bottom: 1px solid #E9EBEE;	
	cursor: pointer;
}

.table_item:hover{
	background-color: #cef1ff;
	
}

.table_item div {
	padding: 7px 0;
	width: 30%;
	text-align: center;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	font-size: 16px;
	padding-bottom: 5px;
}

.button_atraca{
	background-color: #424242;
	border-style: none;
	outline: none;
	border-radius: 80px;
	padding: 2px 13px;
	cursor: pointer;
	color: #fff;
	font-size: 16px;
	word-spacing: 5px;
	font-family: "Titillium Web", sans-serif;
	border: 1px solid #36b8e700;
	margin-right: 100px;
}


.button_atraca:hover {
	border: 1px solid #36b8e7;
	outline: 3px solid #8ad4ee80;
}


