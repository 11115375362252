/*modal*/

.modal {
	margin: 0 auto;
	width: 100%;
	background-color: #F9FAFB;
	display: grid;
	border-radius: 5px;
	padding: 15px;
	color: #1f4d64;
}

.nav {
	font-size: 25px;
	margin-top: -10px;
	border-bottom: 1px solid #bbbbbb;
	color: #8A909B;
	display: flex;
}.nav div{
	cursor: pointer;
}


/*-----------------------------------------------*/

.flex {
	display: flex;
	justify-content: space-between;
	width: 90%;
	margin: 1.5% auto;
}

.center {
	padding: 50px 0;
	text-align: center;
	font-size: 22px;
	font-weight: 600;
}.center div{
	font-size: 18px;
	margin-top: 15px;
	color: #7a7d85;
	font-weight: 500;
}

.navio {
	font-size: 22px;
	font-weight: 700;
	margin: auto 0;
	margin-inline: 30px;
}

button {
	width: 30%;
	color: #ffffff;
	border: none;
	padding: 10px;
	font-weight: 500;
	border-radius: 3px;
	cursor: pointer;
}
.confirmar{
background-color: #0b9c48;
}
.cancelar{
	background-color: #9c0b0b;
}.cancelar:hover{
	background-color: #c90f0f;
}

button:hover {
	background-color: #07c455;
}