/* Adicione estas regras CSS para melhorar a tabela de cargas */
.confirmar {
    padding: 5%;
    text-align: center;
  }
/* Estilo para o elemento .nav */
.nav {
    background-color: #f5f7f5;
    white-space: nowrap; /* Impede a quebra de texto */
    overflow: hidden;
    text-overflow: ellipsis; /* Adiciona reticências (...) se o texto não couber na tela */
    padding: 5px 10px;
    font-weight: bold;
    max-width: 100%; /* Use 100% da largura disponível */
    display: flex; /* Permite alinhar o nome da transportadora e o botão "Voltar" na mesma linha */
    align-items: center; /* Alinha verticalmente no centro */
  }
  

  .abas {
    text-align: center;
    margin: 1% auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .detalhesstatus {
    width: 100%;
    display: flex;
    justify-content:center; /* Ajusta o espaçamento entre os elementos */
    align-items: center;
    gap: 5%; /* Adiciona espaço entre os elementos filhos */
}

.status, .navio {
    padding: 1%;
    color: #ffffff;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.status {
    background-color: #b9c50a;
}

.navio {
    color: #222121;
    font-weight: normal; /* Ajusta o peso da fonte, se necessário */
}

  .modal{

    background-color: #f5f7f5;
  }

/* Estilo para o container da tabela */
.modal.center {  
    text-align: center;
    margin: 1% auto;
  }
  
  /* Estilo para o sumário da tabela */
  .sumario {
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    padding: 5px 10px;
    font-weight: bold;
  }
  
  /* Estilo para as células do sumário */
  .modal.sumario div {
    padding: 5px;
  }
  
  /* Estilo para a lista de cargas */
  .modal.lista {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow-x: auto;
    max-height: 300px; /* Defina a altura máxima desejada */
  }
  
  /* Estilo para as linhas da tabela de cargas */
  .modal.item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  /* Estilo para as células de dados da tabela de cargas */
  .modal.item div {
    flex: 1;
    text-align: left;
    padding: 5px;
  }
  
  /* Estilo para as células de dados da primeira coluna */
  .modal.item div:first-child {
    flex: 2;
    font-weight: bold;
  }
  
  /* Estilo para realçar linhas ao passar o mouse */
  .modal.item:hover {
    background-color: #ffffff;
  }
  .modal.cargas {
    margin: 5%;
    font-weight: 600;
    text-align: center;
    padding: 10px;
    border-radius: 3px;
    overflow: auto;
  }
  
  .tableContainer{
    padding-top: 3%;
  }
  .table {
    font-size: 14px;
    border-radius: 25%;
    border-collapse: collapse;
  }
  
  .table caption {

    width: 100%;
    padding-top: 2%;
  }

  .titulo {
    background-color: #af1515;
    width: 30%;
    color: #fff;
    font-weight: bold;
    font-size: 1.4em;
  }
  
  .table th,
  .table td {
    padding: 10px;
    border: 1px solid #ffffff;
  }
  
  .table th {
    background-color: #c91a1a;
    color: #fff;
    font-weight: bold;
  }
  
  .table tbody tr:nth-child(even) {
    background-color: #ffffff;
  }
  
  .table tbody tr:hover {
    background-color: #ffffff;
  }

  .finalizar {
	width: auto;
	background: #424242;
    font-size: 20px;
	color: white;
	font-family: 'sans-serif';
	border: 0 none;
	border-radius: 35px;
	cursor: pointer;
	transition: 0.3s;
	padding: 7px 45px;
	margin: auto;
	margin-top: 25px;
	margin-left: 2px;	
}.finalizar:hover{
	background: #c91a1a;
	box-shadow: 
	0 0 0 3px #ffffffd8,
	 0 0 0 4px #c91a1a;
}

.customtab {
  align-items: center;
  width: max-content;
  justify-content: center;
  color: #c91a1a;
  
  }
  
  .customtab:hover {
    color: #c91a1a;
    background-color: #f5f7f5;
  }
  
  .customtab:active {
    color: #c91a1a;
    background-color: #f5f7f5;
  }
  
  .formRow {
    display: flex; /* Usa Flexbox para dispor os itens lado a lado */
    flex-wrap: wrap; /* Permite que os itens se movam para a próxima linha, se necessário */
    gap:2%;
  }
  
  .formColumn {
    flex: 1; /* Faz cada coluna ocupar uma parte igual do contêiner */
    min-width: 160px; /* Define uma largura mínima para cada coluna */
    padding: 10px; /* Adiciona um pouco de espaço ao redor dos itens */
    margin-top: 8px; /* Espaço acima da div para separá-la dos outros elementos */
  border: 1px solid #d8c1c1; /* Cor da borda (azul neste caso, mas sinta-se à vontade para mudar) */
  border-radius: 6px; /* Borda arredondada */
  color: #c91a1a; /* Cor do texto */
  font-weight: bold; /* Texto em negrito para o rótulo e conteúdo */
  text-align: center; /* Alinha o texto ao centro */
  gap:2%;
  align-items: center; /* Centraliza os itens na direção transversal (horizontal) */
  justify-content: center; /* Centraliza os itens na direção principal (vertical) */
  }

  /* No seu arquivo Modal.module.css */
.importadorDisplay {
  padding: 10px; /* Espaçamento interno para dar mais espaço ao redor do texto e do rótulo */
  margin-top: 8px; /* Espaço acima da div para separá-la dos outros elementos */
  border: 2px solid #c91a1a; /* Cor da borda (azul neste caso, mas sinta-se à vontade para mudar) */
  border-radius: 10px; /* Borda arredondada */
  color: #c91a1a; /* Cor do texto */
  font-weight: bold; /* Texto em negrito para o rótulo e conteúdo */
  text-align: center; /* Alinha o texto ao centro */
  background-color: #fff0f0; /* Cor de fundo claro */
  display: flex; /* Usa Flexbox para alinhar rótulo e conteúdo */
  flex-direction: column; /* Organiza rótulo e conteúdo em coluna */
  align-items: center; /* Centraliza os itens na direção transversal (horizontal) */
  justify-content: center; /* Centraliza os itens na direção principal (vertical) */
}

/* No seu arquivo Modal.module.css */
.navioNome {
  display: flex; /* Usa Flexbox para alinhar ícone e texto */
  align-items: center; /* Centraliza os itens verticalmente */
  justify-content: center; /* Centraliza os itens horizontalmente */
  font-weight: bold; /* Deixa o texto em negrito */
  color: #424242; /* Define a cor do texto para #424242 */
  padding: 8px; /* Adiciona um espaçamento interno */
  border-radius: 10px; /* Borda arredondada */
  background-color: #f0f8ff; /* Cor de fundo claro */
  margin-top: 8px; /* Espaço acima do elemento */
  width: 100%; /* Faz o elemento ocupar a largura total do contêiner pai */
}

.navioIcone {
  margin-right: 8px; /* Espaço entre o ícone e o texto */
  color: #424242; /* Ajusta a cor do ícone para #424242 */
  font-size: 24px; /* Tamanho do ícone */
}

.formColumnEmpresa {
  display: grid; /* Utiliza o layout de grade */
  grid-template-columns: repeat(3, 1fr); /* Divide em 3 colunas de tamanhos iguais */
  gap: 10px; /* Espaço entre os checkboxes */
  padding: 10px; /* Adiciona um pouco de espaço ao redor dos itens */

  font-weight: bold; /* Texto em negrito */
  text-align: left; /* Alinha o texto à esquerda */
}

.formColumnEmpresa div {
  display: flex;
  align-items: center;
}

input[type="checkbox"] {
  margin-right: 5px; /* Espaço entre o checkbox e o label */
  cursor: pointer;
}

.formColumnEmpresa label {
  cursor: pointer;
  user-select: none; /* Impede que o texto do label seja selecionado */
}
