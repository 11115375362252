/* Estilos para containers principais */
.content {
    margin: 0 auto;
    font-size: 18px;
    width: 100%;
    color: #065986;
    display: grid;
    padding: 15px;
}


/* Estilo do contêiner da navbar */
.nav {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 4px 8px;
	gap: 8px;
    margin-bottom: 10px;
}

/* Item ativo */
.navItemActive {
    font-size: 18px;
    font-weight: bold;
    color: #808080;
    padding: 4px 8px;
    border-radius: 8px;
    cursor: default;
}

/* Estilo do botão "Cadastrar Navio" */
.navItem {
    font-size: 16px;
    color: #ffffff;
    background-color: #1E90FF;
    padding: 4px 8px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.navItem:hover {
    background-color: #3A9FFF;
}

/* Estilo da tabela */
.tableContainer {
    margin-top: 20px;
    width: 100%;
    overflow-x: auto;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.table {
    width: 100%;
    border-collapse: collapse;
    padding-bottom: 10px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    text-align: left;
}

.table th, .table td {
    padding: 10px;
    border: 1px solid #ddd;
}

.table th {
    background-color: #065986;
    color: #ffffff;
    font-weight: bold;
    text-align: left;
}

.table tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Estilo dos campos de inserção em três colunas */
.formContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Cria 3 colunas */
    gap: 10px;
    align-items: start;
    margin-bottom: 20px;
}

.formContainer .inputField {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.submitButtonContainer {
    grid-column: 3; /* Coloca o botão na terceira coluna */
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.submitButton {
    background-color: #065986;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.submitButton:hover {
    background-color: #003366;
}

.error {
    color: #ff0000;
    margin-top: 10px;
}

.statusToggle {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.toggleButton {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
}

/* Responsividade para dispositivos móveis */
@media screen and (max-width: 600px) {
    .formContainer {
        grid-template-columns: 1fr; /* Ajusta para uma coluna em telas pequenas */
    }

    .submitButtonContainer {
        grid-column: 1; /* Mantém o botão na mesma linha no mobile */
        justify-content: center;
    }
}
