/* Estilos da caixa de conteúdo */
.content {
	margin: 0 auto;
	font-size: 19px;
	width: auto;
	color: #424242;
	background-color: #f5f7f5;
	display: grid;
	border-radius: 10px;
	padding: 15px 25px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Adiciona uma sombra ao redor da borda */
	padding-bottom: 5%;
}


/* Estilos da barra de navegação */
.nav {
	display: flex;
	width: auto;
	align-items: center;
	color: #424242;
	gap: 2%;
	flex-wrap: nowrap; /* Impede que os itens quebrem para a próxima linha */
	padding-bottom: 2%;
}

.navbar {
	padding: 10px;
	border-radius: 10px;
	color: #424242;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #a1a1a14d;
}

.active {
	padding: 10px;
	border-radius: 10px;
	color: #f2f2f2;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #c91a1a;
}


.container{
	padding-top: 2%;
}

.formulario{
	display: flex;
    gap: 2%;
	align-items: center;
    
}



.filters {
	
	width:40%;
	color: #424242;
	background-color: #f5f7f5;
	display: grid;
	border-radius: 20px;
	border: 1px solid #7ba776;
	padding: 15px 25px;

}

.formControl {
    width: 100%;
    padding: 15px 5px;
    font-size: 20px;
    border-radius: 3px;
    align-items: center;
}

/*-----------------------------------------------*/
  

.flex {
	margin-top: 0.7vw;
	display: flex;
	justify-content: center;
	text-align: center;
	color: #424242;
	padding: 1px 0px;
    margin-bottom: 2.5vw;
}

.periodo {
	text-align: center;
	color: #424242;
	font-size: 27px;
	font-weight: 700;
}

.data {
	font-weight: 600;
	font-size: 22px;
	letter-spacing: -1px;
	margin-top: -8px;
	border-top: 2px solid #d9d9d9;
}



.status div {
	position: relative;
	top: 50%;

	font-size: 22px;
	font-weight: 600;
	transform: translateY(-50%);
	padding: 4px 7px;
	border-radius: 3px;
}

.OPERANDO {
	background-color: #bff6cd;
	color: #004e07;
}

.PARALISADO {
	background-color: #f79d9d;
	color: #790000;
}

.tara {
	background-color: #F0F3F5;
	width: 100%;
	border: 1px dashed #153240a2;
	font-weight: 600;
	padding-top: 5px;
}

.taratitulo {
	display: flex;
	justify-content: center;
	width: 100%;
	margin: 2% auto;
}

.taratitulo input {
	padding: 3px 35px 3px 15px;
	background-color: #FFFFFF;
	border: 1px solid #424242;
	border-radius: 4px;
}

.taratitulo input:focus {
	outline: 2px solid #8ad4ee80;
}

.taratitulo i {
	margin: 3px 3px 0px -20px;
	cursor: pointer
}

.sumario {
	display: flex;
	justify-content: space-around;
	margin: 5px 0;
}

.sumario div {
	border-right: 1px solid #1f4d6473;
	border-left: 1px solid #1f4d6473;
	width: 100%;
}

.lista {
	background-color: hsl(0, 0%, 100%);
	height: 200px;
	width: 98%;
	margin: auto;
	margin-bottom: 8px;
	overflow-y: auto;
}

.lista::-webkit-scrollbar {
	width: 10px;
}

.lista::-webkit-scrollbar-track {
	box-shadow: inset 0 0 3px grey;
}

.lista::-webkit-scrollbar-thumb {
	background: #424242;
	border-radius: 10px;
}

.item {
	display: flex;
	justify-content: space-between;
	border-top: 1px solid #d8d8d8;
	cursor: pointer;
}

.item:hover {
	background-color: #c7fff6;
}

.item div {
	width: 30%;
	font-weight: 400;
	color: #585858;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}


.barrinha {
	margin-left: 3px;
}

.finalizar {
	background-color: #0A883F;
	color: #ffffff;
	border: none;
	padding: 8px 28px;
	font-weight: 500;
	border-radius: 3px;
	cursor: pointer;
}

.column2{
	display: flex;
    justify-content: center;  /* centraliza horizontalmente */
	width:55%;
}

.navio{
	display:flexbox;
	align-items: center;
	text-align-last: center;
	flex: auto; 
    border-radius: 12px;
    color: var(--main-color);
    font-family: roboto;
    padding: 10px;
    font-size: 35px;
    justify-content: center;
}

.finalizar:hover {
	background-color: #00df5d;
}

.wrap{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
}

.headder2{
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
}





.boxhora{
	
    justify-content:end;
	border: 3px solid #424242;
    display: flex;
	border-radius: 12px;
	background-color: #424242; 
	width: auto;
    color: #ffffff;
    font-family: roboto;
	padding: 15px 20px;
	font-weight:380;
	justify-content: center;
}

.box{
	border: 3px solid #424242;
    display: flex;
	border-radius: 12px;
	background-color: #424242; 
	width: auto;
    color: #ffffff;
    font-family: roboto;
	padding: 15px 20px;
	font-weight:380;
	justify-content: center;
}

.cardinfo{
	border: 0px solid #153240a2;
	padding-left: 2%;
	font-size: 18px;

}


.cardinfo_Doc{
	border: 0px solid #153240a2;
	padding: 0px 0px 0px 0px;
	font-size: 18px;
	padding-left: 3%;
    padding-right: 2%;
	width: 55%;

}




.form_control{
	width: auto;
	padding: 15px 5px;
	font-size: 20px;
	border-radius: 3px;
	border: 1px ;
	align-items: center;
	
}
.submit_button {
	font-size: 20px;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
		
  }

 .form_control select {
	color: #153240;
	font-size: 16px;
	position: relative;
	width: 80%;
 }

 .taratitulo {
	padding: 3px 35px 3px 15px;
	--background-color: #FFFFFF;
	border: 0px solid #153240a2;
	border-radius: 4px;
    flex-wrap: wrap;
	justify-content: end;
	margin-top: -5px;
	
}

/* Estilos gerais da tabela */
.table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
    font-size: 14px;
    text-align: center;
    border: 1px solid #ddd;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1); /* Adicionando uma pequena sombra para dar profundidade */
    border-radius: 3px; /* Característica adicionada do primeiro CSS */
    overflow: hidden;   /* Característica adicionada do primeiro CSS */
}

/* Cabeçalho da tabela */
.table thead {
    background-color: #424242; /* Cor de fundo do cabeçalho */
    color: #ffffff; /* Cor do texto do cabeçalho */
}

.table thead th {
    padding: 8px 12px;
    border: 1px solid #ddd;
    font-weight: bold;
    background-color: #424242;
    color: #f2f2f2;
}

/* Corpo da tabela */
.table tbody td {
    padding: 8px 8px; /* Espaçamento interno nas células */
    border-top: 1px solid #ddd; /* Linha sutil entre as linhas */
}

/* Hover effect */
.table tbody tr:hover {
    background-color: #f5f5f5; /* Cor de fundo ao passar o mouse */
}

/* Destaque para linhas específicas com classe .highlight */
.highlight {
    background-color: #ffff99; /* Cor de fundo para linhas destacadas */
}

/* Strikethrough style for .strikethrough class */
.strikethrough td {
    text-decoration: line-through; /* Tachado para linhas com essa classe */
    color: #a0a0a0; /* Cor do texto para texto tachado */
}


.icon {
    cursor: pointer;
    transition: transform 0.3s;
}

.icon:hover {
    transform: scale(1.1);
}

.nota_download, .nota_download_empty {
    text-align: center;
}

.nota_download_empty {
    opacity: 0.5;
}





.total {
    border: 0px solid #153240a2;
	font-size: 22px;
	font-weight: bold;
    color: #ffffff;
    font-family: roboto;
      
}

.total_documento {
	border: 0px solid #153240a2;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    font-family: roboto;
      
}

/* Cores e Variáveis */
:root {
    --main-color: #424242;
    --secondary-color: #424242;
    --white-color: #ffffff;
}

/* Estilos Gerais */
* {
    box-sizing: border-box;
}

.iconcontainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    padding: 6px;
    border-radius: 8px; /* Canto arredondado */
}

/* Formulário e Seleção */
.formControl select {
    color: var(--main-color);
    font-size: 16px;
    width: 80%;
}

/* Botões */
.submitButton {
    font-size: 20px;
	border-radius: 12px;
	background-color: var(--main-color);
    color: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

/* Cards */
.cards {
    display: flex;
	padding-top: 1%;
    gap: 2%;
    align-items: flex-end;
}

.cardParalisacao {
	text-align-last: center;
	border: 3px solid var(--main-color);
	
}

.cardParalisacao th, .cardParalisacao td {
    padding: 4px 6px; 
    text-align: center;
	vertical-align: middle;
	font-weight:normal;
}

.cardParalisacao th {
    color: #ffffff;
	
    background-color: #424242;
}

.card {
    border: 3px solid var(--secondary-color);
	display:flexbox;
	text-align-last: center;
	flex: auto; 
    border-radius: 12px;
    background-color: var(--main-color);
    color: var(--white-color);
    font-family: roboto;
    padding: 10px;
    font-weight: 380;
    justify-content: center;
}

.strikethrough {
	color: #aa0909;
    text-decoration: line-through;
}

.cardPara {
    border: 3px solid var(--secondary-color);
	width:10%;
	text-align-last: center;
	flex: auto; 
    border-radius: 12px;
    background-color: var(--main-color);
    color: var(--white-color);
    font-family: roboto;
    padding: 10px;
    font-weight: 380;
    justify-content: center;
}

.cardDetails {
    border: 3px solid var(--secondary-color);
	display:flex;
	text-align-last: center;
	flex: 1; 
    font-family: roboto;
}

/* Supondo que você esteja usando um arquivo .module.css */
.search {
    display: flex;
    justify-content:end;
    align-items: center;
    padding: 20px 0;
    background-color: #f7f7f7; /* cor de fundo para diferenciar a seção, opcional */
}

.searchBox {
    display: flex;
    align-items: center;
    padding: 3px;
}

.searchBox select, .searchBox input {
    margin-right: 10px;
    padding: 6px;
}

.searchBox select {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8.5px;
}

.searchBox input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 3px;
}

.linha {
    border-bottom: 1px solid #ffffff; /* Ajuste a cor (#000) conforme necessário */
}

.cardParalisacao abbr {
    text-decoration: none;
    border-bottom: none;
}

/* Estilo base para o tooltip */
.tooltip {
	position: relative;
	display: flexbox;
	cursor: pointer;
	z-index: 2000; /* Garantir que esteja acima da tabela */
  }
  
  /* Estilo para o texto do tooltip */
  .tooltip .tooltiptext {
	visibility: hidden;
	width: auto;
	background-color: #c91a1a;
	color: white;
	text-align: center;
	border-radius: 6px;
	padding: 3%;
	position: absolute;
	z-index: 2001; /* Mais alto que o .tooltip para garantir visibilidade */
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%);
	white-space: nowrap;
	opacity: 0;
	transition: opacity 0.5s ease;
  }
  
  /* Estilo para exibir o tooltip quando o mouse está sobre o elemento */
  .tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
  }
  

.relatoriosCentered {
    text-align: center;
    margin-bottom: 10px; /* adjust as needed */
}

.moegaTable {
    width: 100%;
	border: 0px solid #ffffff;
}

.centered div {
    text-align: center;
    /* Additional styling as needed */
}

.nomeNavio {
    display: flex;
    align-items: center;
	color: #000000;

}

.highlight {
    background-color: #ffff99; /* Cor amarela suave para destaque */
	border: 2px solid #ffcc00; /* Bordar com amarelo mais forte */

}

.cardsTopBar {
    margin-bottom: 20px; /* Espaçamento antes da seção dos cards */
}

.progressContainer {
    margin: 10px 0; /* Espaçamento vertical para separar as barras de progresso */
    padding: 10px;
    background-color: #f8f9fa; /* Cor de fundo do contêiner da barra de progresso */
    border-radius: 5px; /* Bordas arredondadas */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Sombra leve para destaque */
}

.progressLabel {
    font-size: 0.9rem; /* Tamanho da fonte para o rótulo da barra */
    color: #333; /* Cor do texto do rótulo */
    margin-bottom: 5px; /* Espaçamento abaixo do rótulo */
    display: block; /* Faz o rótulo ocupar a linha inteira */
}

.progressBar {
    height: 20px; /* Altura da barra de progresso */
    background-color: #e0e0e0; /* Cor de fundo da barra de progresso */
    border-radius: 5px; /* Bordas arredondadas para a barra de progresso */
    overflow: hidden; /* Garante que o conteúdo interno não saia do contorno da barra */
}

.progressBar div {
    height: 100%; /* Altura da barra de progresso interna */
    background-color: #90caf9; /* Cor de fundo da barra de progresso interna */
    text-align: center; /* Centraliza o texto na barra */
    color: white; /* Cor do texto na barra de progresso interna */
    line-height: 20px; /* Centraliza verticalmente o texto na barra */
    font-size: 0.8rem; /* Tamanho da fonte do texto na barra */
    white-space: nowrap; /* Evita que o texto quebre em várias linhas */
}

.progressPercentage {
    text-align: right; /* Alinha o texto da porcentagem à direita */
    font-size: 0.9rem; /* Tamanho da fonte para o texto da porcentagem */
    color: #555; /* Cor do texto da porcentagem */
    margin-top: 5px; /* Espaçamento acima do texto da porcentagem */
}

.botaoEmail {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    background-color: #d63e2a; /* Azul padrão */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
}

.botaoEmail:hover {
    background-color: #c74b3b; /* Azul escuro */
}

.botaoEmail i {
    margin-right: 5px;
}



@media (max-width: 768px) {
    /* Ajustes gerais para dispositivos móveis */
    body, .content {
        font-size: 16px; /* Ajuste o tamanho da fonte para melhor leitura */
    }

    /* Melhorias para a tabela em dispositivos móveis */
    .table, .table thead, .table tbody, .table th, .table td, .table tr {
        display: block;
    }

    .table thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .table tr {
        margin-bottom: 1rem;
        border: 1px solid #ddd;
    }

    .table td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
        text-align: left;
    }

    .table td:before {
        font-weight: bold;
        position: absolute;
        top: 12px;
        left: 10px;
        content: attr(data-title); /* Use o atributo data-title para adicionar o nome da coluna */
    }

    /* Ajustes adicionais para melhorar a disposição dos elementos em telas pequenas */
    .nav, .filters, .formulario, .flex, .wrap {
        flex-direction: column;
        align-items: stretch;
    }

    /* Ajustar o espaçamento e o tamanho dos elementos para telas menores */
    .content, .nav, .filters, .formulario, .searchBox {
        padding: 10px;
    }

	.card {
        /* Ajusta a largura para garantir que o card utilize todo o espaço disponível */
        width: auto;
        /* Reduz o padding para melhor aproveitamento do espaço */
        padding: 10px;
        /* Reduz o tamanho da fonte para adequação ao espaço disponível */
        font-size: 14px;
        /* Opcional: Ajusta a margem para garantir espaço entre os cards */
        margin-bottom: 15px;
        /* Melhora a visualização em dispositivos com tela pequena */
        display: block;
        box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Opcional: suaviza a sombra */
    }

    /* Se os cards estiverem em um layout de grade, ajuste para quebrar em colunas únicas */
    .cards {
        display: block;
    }

    /* Ajustes para elementos dentro dos cards, se necessário */
    .card img {
        /* Ajusta imagens para serem totalmente responsivas dentro do card */
        max-width: 100%;
        height: auto;
    }

    .card-title, .card-text {
        /* Ajusta o tamanho da fonte para elementos específicos do card, se necessário */
        font-size: 16px;
    }
}
