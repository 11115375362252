.content {
	margin: 0 auto;
	font-size: 19px;
	width: auto;
	color: #424242;
	background-color: #f5f5f5;
	display: grid;
	border-radius: 10px;
	padding: 10px 15px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Adiciona uma sombra ao redor da borda */
	padding-bottom: 5%;
}
  
/* Estilos da barra de navegação */
.nav {
	display: flex;
	width: auto;
	align-items: center;
	color: #424242;
	gap: 2%;
	flex-wrap: nowrap; /* Impede que os itens quebrem para a próxima linha */
	padding-bottom: 2%;
}

.navbar {
	padding: 10px;
	border-radius: 10px;
	color: #424242;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #a1a1a14d;
}

.active {
	padding: 10px;
	border-radius: 10px;
	color: #f2f2f2;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #c91a1a;
}
.tabContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #ddd;
}

.tabButton {
  padding: 5px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  outline: none;
}

.tabButton:hover {
  background-color: #0057b300;
}

.radioGroup {
  display: flex;
  justify-content: center; /* Centraliza os botões horizontalmente */
  gap: 20px; /* Espaçamento entre os botões */
  margin-bottom: 20px; /* Espaçamento inferior */
}

.radioGroup label {
  display: flex;
  align-items: center; /* Alinha o texto e o botão verticalmente */
  font-size: 16px;
  cursor: pointer;
}

.checklist {
  padding: 20px;
  max-width: 70%;
  margin: 0 auto;
}

.checklist h2 {
  text-align: center;
  margin-bottom: 20px;
}

.checklist label {
  display: block;
  margin-bottom: 10px;
}

.checklist input[type="text"],
.checklist input[type="number"],
.checklist input[type="datetime-local"],
.checklist textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.checklist button {
  width: 100%;
  padding: 10px;
  background-color: #02670f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.photoList ul {
  list-style: none;
  padding: 0;
}

.photoList li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.photoList li:hover {
  background-color: #f0f0f0;
}

.thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 10px;
}

.removeButton {
  background: #990f0f;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 18px;
  cursor: pointer;
}

.removeButton:hover {
  color: rgb(255, 255, 255);
}

.containerType {
  margin-top: 20px;
  text-align: center;
}

.containerType h3 {
  margin-bottom: 10px;
  font-size: 18px;
}

.containerOptions {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.option input {
  display: none; /* Oculta o botão de rádio padrão */
}

.iconWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid transparent;
  padding: 10px;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.iconWrapper:hover {
  border-color: #016e0a; /* Cor de destaque ao passar o mouse */

}

input:checked + .iconWrapper {
  border-color: #016e0a; /* Cor de destaque ao selecionar */
  background-color: #eefaef;
}

.icon {
  width: 120px; /* Ajuste o tamanho da imagem */
  height: auto;
}

.option p {
  margin-top: 5px;
  font-size: 14px;
}

.scheduleContainer {
  width: 100%; /* A largura do contêiner principal será 100% */
  display: flex; /* Para organizar os itens em linha */
  justify-content: space-between; /* Espaço entre as colunas */
  gap: 10px; /* Espaçamento entre os elementos */
}


.timeSlotss {
  display: flex;
  width: 100%;
  font-size: 13px;
  border: 1px solid #ccc;
  flex-direction: column;
  align-items: flex-start;
}


.calendar {
  display: flex;
  width: 40%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.timeSlots {
  display: flex;
  width: 100%;
  font-size: 13px;
  flex-direction: column;
  align-items: flex-start;
}

input[type="date"] {
  padding: 10px; /* Espaçamento interno */
  font-size: 16px; /* Tamanho da fonte */
  border: 1px solid #ccc; /* Borda padrão */
  border-radius: 5px; /* Bordas arredondadas */
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Transição suave */
}

input[type="date"]:focus {
  border-color: #007bff; /* Cor da borda ao focar */
  box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5); /* Sombra ao focar */
  outline: none; /* Remove a borda padrão ao focar */
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer; /* Indica que o seletor é clicável */
}

.slots {
  display: grid;
  width: 90%;
  grid-template-columns: repeat(4, 1fr); /* 4 colunas para os horários */
  gap: 10px;
}

.slots button {
  padding: 8px; /* Ajuste do padding para uma aparência melhor */
  background-color: #ebebeb; /* Cor padrão */
  color: #2c2a2a; /* Cor do texto padrão */
  border: 1px solid #ccc; /* Borda padrão */
  border-radius: 5px; /* Cantos arredondados */
  cursor: pointer; /* Indicador de clicável */
  transition: all 0.3s ease; /* Transição suave para mudanças */
}

.slots button:hover {
  background-color: #800404; /* Cor de fundo ao passar o mouse */
  color: white; /* Texto branco para contraste */
}

.slots button:active {
  background-color: #4d0404; /* Cor mais escura ao clicar */
  color: white; /* Texto branco */
  transform: scale(0.95); /* Efeito de "pressionado" */
  border: 1px solid #4d0404; /* Borda mais consistente com o fundo */
}

.slots button.active {
  background-color: #800404; /* Cor de fundo do botão ativo */
  color: white; /* Texto branco */
  border: 1px solid #800404; /* Borda consistente */
  transform: scale(1); /* Garante que o botão ativo não seja pressionado */
}

.showMoreButton {
  grid-column: span 4; /* Ocupa toda a largura da grade */
  font-size: 13px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
}

.showMoreButton:hover {
  background-color: #0056b3;
}

.tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tabButton {
  padding: 10px 15px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tabButton:hover {
  background-color: #ddd;
}

.activeTab {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.slots {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 colunas */
  gap: 10px;
}

.moreButtonContainer button {
  display: flex;
  justify-content: flex-end; /* Move o botão para o canto direito */
  background-color: #ffffff00;
  color: rgb(46, 46, 46);
  margin-top: 20px;
}

@media (max-width: 768px) {
  
  .modalContent {
    width: 100%;
  }

  .content {
    margin: 0 auto;
    font-size: 19px;
    width: auto;
    color: #424242;
    display: grid;
    padding: 1px 1px;
    background-color: #f5f5f500;
    padding-bottom: 5%;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0); /* Adiciona uma sombra ao redor da borda */

  }
  .nav {
    flex-direction: column; /* Empilha os itens verticalmente */
    align-items: flex-start; /* Alinha os itens no início */
    gap: 10px; /* Espaçamento vertical entre os itens */
  }

  .navbar,
  .active {
    width: 100%; /* Itens ocupam toda a largura */
    text-align: center; /* Centraliza o texto */
  }

  .navbar {
    font-size: 14px; /* Reduz o tamanho da fonte */
    padding: 8px; /* Ajusta o preenchimento */
  }

  .active {
    font-size: 14px; /* Reduz o tamanho da fonte */
    padding: 8px; /* Ajusta o preenchimento */
  }

  .checklist {
    padding: 1px;
    max-width: 95%;
    margin: 0 auto;
  }

  .slots {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr); /* 4 colunas para os horários */
    gap: 3px;
  }
}
