.notform {
	text-align: center;
	font-size: 29px;
	padding: 150px;
	color: #1f4d64;
}

/* Estilos da caixa de conteúdo */
.content {
	margin: 0 auto;
	font-size: 19px;
	width: auto;
	color: #424242;
	background-color: #f5f7f5;
	display: grid;
	border-radius: 10px;
	padding: 15px 25px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Adiciona uma sombra ao redor da borda */
	padding-bottom: 5%;
}


/* Estilos da barra de navegação */
.nav {
	display: flex;
	width: auto;
	align-items: center;
	color: #424242;
	gap: 2%;
	flex-wrap: nowrap; /* Impede que os itens quebrem para a próxima linha */
	padding-bottom: 2%;
}

.navbar {
	padding: 10px;
	border-radius: 10px;
	color: #424242;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #a1a1a14d;
}

.active {
	padding: 10px;
	border-radius: 10px;
	color: #f2f2f2;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #c91a1a;
}



/*-----------------------------------------------*/

.table {
	width: 100%;
	border-collapse: collapse;
	margin: 20px 0;
	font-size: 16px;
	text-align: center;
	border: 1px solid #ddd;
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
	/* Adicionando uma pequena sombra para dar profundidade */
	border-radius: 3px;
	/* Característica adicionada do primeiro CSS */
	overflow: hidden;
	/* Característica adicionada do primeiro CSS */
	table-layout: auto;
	/* Ajuste automático das colunas na horizontal */
}

.table td:nth-child(2) {
	white-space: nowrap;
}


.table thead {
	background-color: #f2f2f2;
}

.table th,
.table td {
	padding: 8px 12px;
	border: 1px solid #ddd;
}

/* Estilização do cabeçalho da tabela */
.table thead th {
	padding: 8px 12px;
	border: 1px solid #ddd;
	font-weight: bold;
	background-color: #424242;
	color: #f2f2f2;
}

/* Zebra striping para linhas pares */
.table tbody tr:nth-child(even) {
	background-color: #f5f5f5;
}

/* Efeito de hover nas linhas da tabela */
.table tbody tr:hover {
	background-color: #d5ffcf;
}

.pesoContainer {
    float: left;
}

.pesoContainer input {
    width: 65%;
	font-size: 16px;
    float: left;
    border: 1px solid #ccc; /* Borda sutil */
    border-radius: 4px; /* Bordas arredondadas */
	white-space: nowrap;
    
}

.acoesContainer {
    float: right;
}

/* Limpar floats após cada célula */
td::after {
    content: "";
    display: table;
    clear: both;
}

/* Estilização adicional para os ícones, se necessário */
.acoesContainer span {
   
    margin-left: 5px;
}


.leftColumn {
    flex: 1;
	width: 50%;
    margin-right: 20px; /* Espaçamento entre as colunas */
	
}

.leftColumn2 {
    flex: 1;
	width: 100%;
    margin-right: 20px; /* Espaçamento entre as colunas */
	color: #113c0a;
	font-size: 20px;
	font-weight: 600;
}

.rightColumn {
    flex: 1;
	gap: 1;
	
}

.card {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 15px;
    margin-bottom: 20px;
	width: 50%;
}

.cardRed {
    background-color: #fccbcb;
    border: 2px solid #690404;
}


.cardItem {
    margin-bottom: 10px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cardItem input[type="text"] {
    flex-grow: 2; /* Faz com que o input ocupe o espaço disponível */
	width: 10%;
    padding: 4px; /* Espaçamento interno para facilitar a digitação */
    margin-left: 10px; /* Espaço entre o título e o campo de entrada */
    border: 1px solid #ccc; /* Borda sutil */
    border-radius: 4px; /* Bordas arredondadas para uma aparência moderna */
    font-size: 16px; /* Tamanho de fonte confortável para leitura */
    color: #333; /* Cor do texto */
    background-color: #fff; /* Cor de fundo do campo de entrada */

    /* Adiciona um pouco de sombra para um efeito de elevação ao focar no campo */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.cardItem input[type="text"]:focus {
    outline: none; /* Remove o contorno padrão para foco */
    border-color: #428238; /* Cor da borda ao focar */
    box-shadow: 0 2px 5px rgba(0, 128, 0, 0.2); /* Sombra mais proeminente ao focar */
}


.cardItemTitle {
    font-weight: bold;
    margin-right: 5px;
}

/* Estilização responsiva para dispositivos móveis */
@media (max-width: 768px) {
    .notform {
        font-size: 20px;
        padding: 50px;
    }

    .content {
        display: block;
        padding: 10px;
    }

	.nav {
        flex-direction: column; /* Empilha os itens verticalmente */
    }

    .navbar, .active {
        width: 100%; /* Faz com que cada item da barra de navegação ocupe toda a largura disponível */
        margin-bottom: 5px; /* Adiciona um espaço entre os itens da navbar */
    }

    /* Estilo opcional para um botão de menu de hambúrguer */
    .menu-toggle {
        display: block;
        background-color: #424242;
        color: #fff;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        font-size: 20px;
        cursor: pointer;
    }

    /* Oculta os itens da navbar até que o menu de hambúrguer seja clicado */
    .nav-content {
        display: none;
        width: 100%;
    }

    /* Mostra os itens da navbar quando o menu de hambúrguer está ativo */
    .nav-content.active {
        display: flex;
        flex-direction: column;
    }

    .card, .pesoContainer, .acoesContainer {
        width: 100%;
    }

    .table, .cardItem input[type="text"] {
        font-size: 14px;
    }
}