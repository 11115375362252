:root {
	--modal-bg-color: #ffffff;
	--modal-border-radius: 12px;
	--modal-padding: 30px;
	--modal-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
	
	--text-color: #333333;
	--heading-color: #792020;
	
	--button-radius: 6px;
	--button-font-size: 15px;
	--button-padding: 12px 25px;
	--button-finalizar-bg: #942b2b;
	--button-finalizar-hover: #792020;
	--button-cancelar-bg: #d9534f;
	--button-cancelar-hover: #c9302c;
	
	--overlay-bg: rgba(0, 0, 0, 0.75);
  }
  
  .modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 500px;
	max-width: 90%;
	padding: var(--modal-padding);
	background-color: var(--modal-bg-color);
	box-shadow: var(--modal-shadow);
	border-radius: var(--modal-border-radius);
	z-index: 1000;
	transition: all 0.3s ease;
	opacity: 1;
  }
  
  .modalButtonContainer {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
  }
  
  h2 {
	margin-top: 0;
	margin-bottom: 20px;
	font-size: 26px;
	color: var(--heading-color);
	text-align: center;
  }
  
  p {
	font-size: 16px;
	margin-bottom: 10px;
	color: var(--text-color);
  }
  
  .finalizar,
  .cancelar {
	padding: var(--button-padding);
	font-size: var(--button-font-size);
	color: #ffffff;
	border: none;
	border-radius: var(--button-radius);
	cursor: pointer;
	transition: background-color 0.3s ease;
  }
  
  .finalizar {
	background-color: var(--button-finalizar-bg);
  }
  
  .finalizar:hover {
	background-color: var(--button-finalizar-hover);
  }
  
  .cancelar {
	background-color: var(--button-cancelar-bg);
  }
  
  .cancelar:hover {
	background-color: var(--button-cancelar-hover);
  }
  
  .overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--overlay-bg);
	z-index: 999;
	transition: opacity 0.3s ease;
  }
  