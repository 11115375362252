.content {
	margin: 0 auto;
	font-size: 19px;
	width: auto;
	color: #424242;
	background-color: #f5f7f5;
	display: grid;
	border-radius: 10px;
	padding: 15px 25px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Adiciona uma sombra ao redor da borda */
	padding-bottom: 5%;
}


/* Estilos da barra de navegação */
.nav {
	display: flex;
	width: auto;
	align-items: center;
	color: #424242;
	gap: 2%;
	flex-wrap: nowrap; /* Impede que os itens quebrem para a próxima linha */
	padding-bottom: 2%;
}

.navbar {
	padding: 10px;
	border-radius: 10px;
	color: #424242;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #a1a1a14d;
}

.active {
	padding: 10px;
	border-radius: 10px;
	color: #f2f2f2;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #c91a1a;
}

.quantidade input {
	width: 100%;
	padding: 8px;
	box-sizing: border-box;
	border: 1px solid #ccc; /* Exemplo de borda */
	border-radius: 4px; /* Exemplo de arredondamento de bordas */
	/* Adicione quaisquer outros estilos que o input tenha */
  }

/* Grid layout for form fields */
.columns {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 3 columns layout for desktop */
    gap: 20px; /* Space between columns */
    margin-top: 20px;
}

.nome, .cpf, .empresa, .status {
    width: 100%;
    font-size: 1rem;
}

.NomeGrupo, .NomeReduzido {
    width: 100%;
    font-size: 1rem;
}

.masked-input {
    background-color: #fff; /* Example: white background */
    color: #333; /* Example: dark text */
    /* Additional specific styles for MaskedInput */
}

/* Cliente.module.css */

/* Estilos para o campo CNPJ */
.cnpj input {
    font-size: 1rem;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 1rem;
}


label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

input[type="text"], input[type="CPF"], select {
    font-size: 1rem;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 1rem;
}

.submitButton {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 10px;
    width: 100%;
}

.table {
	border-collapse: collapse;
	margin: 20px 0;
	font-size: 16px;
	text-align: center;
	border: 1px solid #ddd;
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
	/* Adicionando uma pequena sombra para dar profundidade */
	border-radius: 3px;
	/* Característica adicionada do primeiro CSS */
	overflow: hidden;
	/* Característica adicionada do primeiro CSS */
	table-layout: auto;
	/* Ajuste automático das colunas na horizontal */
  }
  
  .table td:nth-child(2) {
	white-space: nowrap;
  }
  
  .table thead {
	background-color: #f2f2f2;
  }
  
  .table th,
  .table td {
	padding: 8px 12px;
	border: 1px solid #ddd;
  }
  


  /* Estilização do cabeçalho da tabela */
  .table thead th {
	padding: 8px 12px;
	border: 1px solid #ddd;
	font-weight: bold;
	background-color: #c91a1a;
	color: #f2f2f2;
  }
  
  /* Zebra striping para linhas pares */
  .table tbody tr:nth-child(even) {
	background-color: #f5f5f5;
  }
  
  /* Efeito de hover nas linhas da tabela */
  .table tbody tr:hover {
	background-color: #ffcfcf;
  }

/* Responsiveness for mobile devices */
@media (max-width: 768px) {
    .content {
        font-size: 16px; /* Smaller font size for mobile */
        padding: 10px;
        width: 100%; /* Full width for mobile */
    }

    .nav {
        flex-direction: column; /* Stacking nav items vertically */
        width: 100%;
    }

    .columns {
        grid-template-columns: 1fr; /* Single column layout for mobile */
    }

    input[type="text"], select {
        font-size: 0.9rem; /* Adjusted font size for mobile */
    }

    .submitButton {
        margin-top: 15px;
    }
}