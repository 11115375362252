 .form_control {
	width: 90%;
	margin-top: 2vh;
}

.form_control {
	font-size: 16px;
	height: 70px;
}

.form_control input{
	font-size: 16px;
	width: 90%;
	padding: 8px 5px;
	transition: 0.1s;
	margin-top: 3px;
	font-size: 15px;
	border-radius: 3px;
	border: 1px solid #ccc;
}

.form_control input:focus {
	border: 1px solid #8ad4ee;
	outline: 3px solid #8ad4ee80;
}