.notform {
	text-align: center;
	font-size: 29px;
	padding: 150px;
	color: #1f4d64;
}

.content {
	margin: 0 auto;
	width: auto;
	color: #424242;
	background-color: #f5f7f5;
	display: grid;
	border-radius: 20px;
	border: 1px solid #7ba776;
	padding: 15px 25px;

}

.nav {
	display:flex;
	width:fit-content;
	justify-content:center;
	align-items: center;
	color: #eeeeee;
	background-color: #424242;
	padding: 10px;
	border-radius: 25px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.nav div {
	padding: 0 1vw;
	margin-bottom: -1px;
	cursor: pointer;
}

.nav a {
	text-decoration: none;
	color: #bbbbbb;
}

.active {
	color: #ffffff;
	border-bottom: 3px solid #ffffff;
}



/*-----------------------------------------------*/

.filters {
	color: #424242;
	background-color: #f5f7f5;
	display: grid;
	border-radius: 20px;
	border: 1px solid #7ba776;
	padding: 15px 25px;
}

.flex {
	margin-top: 0.7vw;
	display: flex;
	justify-content: center;
	text-align: center;
	color: #424242;
	padding: 1px 0px;
    margin-bottom: 2.5vw;
}

.periodo {
	text-align: center;
	color: #1f4d64;
	font-size: 27px;
	font-weight: 700;
}

.data {
	font-weight: 600;
	font-size: 22px;
	letter-spacing: -1px;
	margin-top: -8px;
	border-top: 2px solid #d9d9d9;
}

.nota_download {
	cursor: pointer;
}

.nota_download_empty {
	cursor: auto;
}

.status div {
	position: relative;
	top: 50%;

	font-size: 22px;
	font-weight: 600;
	transform: translateY(-50%);
	padding: 4px 7px;
	border-radius: 3px;
}

.OPERANDO {
	background-color: #bff6cd;
	color: #004e07;
}

.PARALISADO {
	background-color: #f79d9d;
	color: #790000;
}

.tara {
	background-color: #F0F3F5;
	width: 100%;
	border: 1px dashed #153240a2;
	font-weight: 600;
	padding-top: 5px;
}

.taratitulo {
	display: flex;
	justify-content: center;
	width: 100%;
	margin: 2% auto;
}

.taratitulo input {
	padding: 3px 35px 3px 15px;
	background-color: #FFFFFF;
	border: 1px solid #424242;
	border-radius: 4px;
}

.taratitulo input:focus {
	outline: 2px solid #8ad4ee80;
}

.taratitulo i {
	margin: 3px 3px 0px -20px;
	cursor: pointer
}

.sumario {
	display: flex;
	justify-content: space-around;
	margin: 5px 0;
}

.sumario div {
	border-right: 1px solid #1f4d6473;
	border-left: 1px solid #1f4d6473;
	width: 100%;
}

.lista {
	background-color: hsl(0, 0%, 100%);
	height: 200px;
	width: 98%;
	margin: auto;
	margin-bottom: 8px;
	overflow-y: auto;
}

.lista::-webkit-scrollbar {
	width: 10px;
}

.lista::-webkit-scrollbar-track {
	box-shadow: inset 0 0 3px grey;
}

.lista::-webkit-scrollbar-thumb {
	background: #1f4d64;
	border-radius: 10px;
}

.item {
	display: flex;
	justify-content: space-between;
	border-top: 1px solid #d8d8d8;
	cursor: pointer;
}

.item:hover {
	background-color: #c7fff6;
}

.item div {
	width: 30%;
	font-weight: 400;
	color: #585858;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}


.barrinha {
	margin-left: 3px;
}

.finalizar {
	background-color: #0A883F;
	color: #ffffff;
	border: none;
	padding: 8px 28px;
	font-weight: 500;
	border-radius: 3px;
	cursor: pointer;
}

.navio{
	font-size: 25px;
	font-weight: 700;
}

.finalizar:hover {
	background-color: #00df5d;
}

.wrap{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
}

.headder2{
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
}

.cards{
	border-radius: 2%;
    display: flex;    
    width: 70%;
	padding-left: 1rem;
}

.box{
	border: 3px solid #424242;
    display: flex;
	border-radius: 12px;
	background-color: #424242; 
	width: 103%;
    color: #ffffff;
    font-family: roboto;
	padding: 15px 20px;
	font-weight:380;
	justify-content: center;
}

.box2{
	border: 3px solid #424242;
    display: flex;
	border-radius: 12px;
	background-color: #424242; 
	width: 103%;
    color: #ffffff;
    font-family: roboto;
	padding: 15px 20px;
	font-weight:380;
	justify-content: center;
}

.operationTiming {
    flex: 1;
    text-align: left;
    padding-right: 10px;
}

/* General card container with improved box shadow and padding */
.cardinfo {
    background-color: #f2f7f2;
	width: 100%;
	padding-left: 1rem;
    border: 1px solid #e0e0e0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
    overflow: hidden;
}

/* Uniform styling for all titles within the card */
.cardinfo .tituloinfo {
    display: block; /* Makes the title go on a new line */
    color: #424242;
}

.boldGreenLabel {
    font-weight: bold;
    color: #424242; /* Adjust the color code to the green you are using */
}

.normalLabel {
    font-weight: normal;
    color: #000; /* or any other color for the navio name */
}


/* Specific styling for the operationTiming container to distribute space */
.operationTiming {
    display: flex;
    justify-content: space-between;
    align-items: center;
	padding-bottom: 0.5rem;
}

.oneline {
	padding-bottom: 0.5rem;
}

/* Full-width spans with padding for better alignment */
.operationTiming span {
    flex: 1;
    padding: 0 10px;
}

/* Hover effect for interactive elements */
.cardinfo div:hover {
    background-color: #d6f7d1;
}

/* Media query for responsive adjustments */
@media screen and (max-width: 768px) {
    .cardinfo {
        padding: 15px;
    }
    
    .operationTiming {
        flex-direction: column; /* Stack the timings on small screens */
    }
    
    .operationTiming span {
        text-align: left;
        padding: 5px 0; /* Adjust padding for stacked layout */
    }
}



.cardinfo2{
	border: 0px solid #153240a2;
	padding-left: 2%;
	font-size: 18px;
}


.form_control{
	width: 100%;
    padding: 15px 5px;
    font-size: 20px;
    border-radius: 3px;
    align-items: center;
}

.submit_button {
	font-size: 20px;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
		
  }

 .form_control select {
	color: #153240;
	font-size: 16px;
	position: relative;
	width: 80%;
 }

 .taratitulo {
	padding: 3px 35px 3px 15px;
	--background-color: #FFFFFF;
	border: 0px solid #153240a2;
	border-radius: 4px;
    flex-wrap: wrap;
	justify-content: end;
	margin-top: -5px;
}

table {
width: -100px;
border: 1px solid #000000a2;
border-radius: 5px;
justify-content: center;
}

th {
	width: 600px;
}



.total {
    border: 0px solid #153240a2;
	font-size: 22px;
	font-weight: bold;
    color: #ffffff;
    font-family: roboto;
      
}

.total_documento {
	border: 0px solid #153240a2;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    font-family: roboto;
      
}

.customtable {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: center;
    border: 1px solid #ddd;
}

.customtable thead {
    background-color: #f2f2f2;
}

.customtable th, .custom-table td {
    padding: 8px 12px;
    border: 1px solid #ddd;
}

/* Zebra striping */
.customtable tr:nth-child(even) {
    background-color: #f5f5f5;
}

/* Hover effect */
.customtable tr:hover {
    background-color: #d5ffcf;
}

/* Header font style */
.customtable th {
    font-weight: bold;
    background-color: #424242;
	color: #f2f2f2;
}

/* Adjusting for a cleaner look */
.customtable {
    box-shadow: 0 1px 10px rgba(0,0,0,0.1);
    border-radius: 3px;
    overflow: hidden;
}

.rankingcontainer {
    display: flex;
	gap: 2%;
    justify-content: center;
    padding: 20px 0;
    border-radius: 8px;
}

.ranking1 {
    display:flexbox;
	font-weight: 600;
	font-size: 22px;
    justify-content: center;
	text-align: center;
}

.customtableranking {
    width: 100%;
    max-width: 800px;
	padding: 10px 0;
    border-collapse: collapse;
    font-size: 16px;
    text-align: center;
}

.customtableranking thead {
    background-color: #424242;
}

.customtableranking th, .customtableranking td {
    padding: 12px 16px;
    border: 1px solid #ccc;
}

.customtableranking tr:nth-child(even) {
    background-color: #e6e6e6;
}

.customtableranking th {
    font-weight: bold;
    color: #fff;
}

.position.first {
    color: gold;
}

.position.second {
    color: silver;
}

.position.third {
    color: #cd7f32; /* Bronze color */
}

.position {
    font-size: 24px;
    font-weight: bold;
}

/* Estilo básico do botão */
.downloadButton {
    background-color: #424242;
    color: white;
    padding: 8px 15px; /* Diminui o padding para tornar o botão menor */
    border: none;
    border-radius: 5px;
    font-size: 14px; /* Diminui o tamanho da fonte */
    cursor: pointer;
    transition: all 0.3s; /* Essa transição afetará todas as propriedades alteradas */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(0);
    position: relative; /* Necessário para o pseudo-elemento */
    overflow: hidden; /* Esconde os efeitos de animação fora do botão */
	padding: 6px 6px; 
}

/* Efeito hover */
.downloadButton:hover {
    background-color: #5a8f52;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1); /* Aumenta a sombra um pouco */
}

/* Efeito active */
.downloadButton:active {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transform: translateY(2px);
}

.downloadButton:active:after {
    width: 300%;
    height: 300%;
    background-color: rgba(255, 255, 255, 0);
}

.lineprod {
    display: flex; /* Define o contêiner como flexbox */
    justify-content: space-between; /* Distribui os itens uniformemente dentro do contêiner */
    align-items: center; /* Centraliza os itens verticalmente */
    padding: 10px; /* Adiciona um pouco de espaço interno para não ficar muito apertado */
	font-weight: 600;
	font-size: 22px;
}

.search {
    display: flex;
    justify-content:space-between;
    align-items: center;
    background-color: #f7f7f7; /* cor de fundo para diferenciar a seção, opcional */
}

.searchBox {
    position: relative; /* para posicionar o ícone dentro */
    width: 300px;
    height: 40px;
}

.searchBox input {
    width: 100%;
    height: 100%;
    padding: 10px 40px 10px 15px; /* 40px de padding à direita para acomodar o ícone */
    border: 2px solid #ddd;
    border-radius: 20px; /* bordas arredondadas */
    outline: none;
    font-size: 15px;
    transition: border-color 0.3s; /* suaviza a transição da cor da borda */
}

.searchBox input:focus {
    border-color: #424242; /* muda a cor da borda no foco, pode ajustar a cor */
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 15px;
    text-align: center;
    border: 1px solid #ddd;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1); /* Adicionando uma pequena sombra para dar profundidade */
    border-radius: 3px; /* Característica adicionada do primeiro CSS */
    overflow: hidden;   /* Característica adicionada do primeiro CSS */
}

.gftitle {
	padding-top: 2%;
	text-align: center;
	font-size: 24px;
	color: #222121;
	margin-bottom: 20px;
	font-weight: bold;
  }

/* Estilização do cabeçalho */
.table thead {
    background-color: #f2f2f2;
}

.table thead th {
    padding: 8px 12px;
    border: 1px solid #ddd;
    font-weight: bold;
    background-color: #424242;
    color: #f2f2f2;
}

/* Estilização do corpo */
.table tbody td {
    padding: 8px 12px;
    border: 1px solid #ddd;
}

/* Zebra striping */
.table tbody tr:nth-child(even) {
    background-color: #f5f5f5;
}

/* Hover effect */
.table tbody tr:hover {
    background-color: #d5ffcf;
}
