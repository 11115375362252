.modal {
	margin: 0 auto;
	font-size: 19px;
	width: 100%;
	color: #424242;
	background-color: #f5f7f5;
	display: grid;
	padding: 15px 25px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Adiciona uma sombra ao redor da borda */
	padding-bottom: 5%;
}
/* Estilos da barra de navegação */
.nav {
	display: flex;
	width: auto;
	align-items: center;
	color: #424242;
	gap: 2%;
	flex-wrap: nowrap; /* Impede que os itens quebrem para a próxima linha */
}

.navbar {
	padding: 10px;
	border-radius: 10px;
	color: #424242;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #a1a1a14d;
}

.active {
	padding: 10px;
	border-radius: 10px;
	color: #f2f2f2;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #c91a1a;
}


/*-----------------------------------------------*/

.flex {
	display: flex;
	justify-content: space-between;
	width: 90%;
	margin: 1.5% auto;
}

.center {
	margin-top: 20px;
	text-align: center;
	font-size: 22px;
	font-weight: 600;
}.center div{
	font-size: 18px;
	margin-top: 5px;
	color: #7a7d85;
	font-weight: 500;
}

.inputbox{
	width: 40%;
	text-align: center;
	color: #5f6166;
	font-size: 18px;
	margin: 10px auto;
	margin-bottom: 35px;
}

button {
	width: 30%;
	color: #ffffff;
	border: none;
	padding: 10px;
	font-weight: 500;
	border-radius: 3px;
	cursor: pointer;
}
.confirmar{
background-color: #0b9c48;
}
.cancelar{
	background-color: #9c0b0b;
}.cancelar:hover{
	background-color: #c90f0f;
}

button:hover {
	background-color: #f03232;
}