.header {
    border-bottom: 1px solid rgba(53, 53, 53, 0.356);
  }
  .conteudo{
    margin-right: 1%;
    display: flex;
    justify-content:space-between;
    margin-left: 7%;
    align-items: center;
  }
  .logo {
    width: 105px;
    margin-bottom: 2px;
    margin-right: 10px; /* Espaçamento entre o logo e o texto */
  }
  
  /* Estilos adicionais para .nome e outros elementos conforme necessário */
  