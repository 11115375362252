/* styles.css */

@import url('//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css?family=Titillium+Web:300');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');


@font-face {
  font-family: 'sans-serif';
  font-style: normal;
  font-weight: 400;
  src: local('sans-serif'), local('sans-serif'), url('http://themes.googleusercontent.com/static/fonts/titilliumweb/v2/anMUvcNT0H1YN4FII8wpr24bNCNEoFTpS2BTjF6FB5E.woff') format('woff');
}

#menu {
  height: 100%;
  background-color: #e2e4e2;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.menu2 {
  background-color: #424242;
}

.menu-horizontal {
  font-family: 'Nunito', sans-serif;

  background-color: #fff;
  width: 60px; /* Largura inicial do menu */
  transition: width 0.3s ease-in-out;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  opacity: 1;
  border-right: 1px solid rgba(53, 53, 53, 0.356); /* Adiciona uma borda verde de 2px */
}

.menu-horizontal.active {
  width: auto; /* Largura expandida do menu */
  
}

.menu-horizontal ul {
  list-style: none;
  padding: 0;
  margin: 0;

}

.menu-horizontal li {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.menu-horizontal li:hover {
  background-color: #bbbbbb;
}


.menu-horizontal .menu_text {
  margin-left: 15px;
  margin-right: 15px;
  display: none;
  color: #424242;
  font-size: 1rem; /* ou '16px' se preferir um tamanho fixo */
  transition: display 0.3s ease;
  font-family: 'Nunito', sans-serif;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}



.menu-horizontal.active .menu_text {
  display: inline-block; /* Mostra o texto quando o menu está expandido */
  font-family: 'Nunito', sans-serif;

}

.menu-horizontal i {
  font-size: 18px;
  color: #424242;
  padding: 5px 16px 5px 6px;
}

@media (max-width: 768px) {
  .menu-horizontal {
    width: auto; /* Largura do menu recolhido em dispositivos móveis */
  }

  .menu-horizontal.active {
    width: auto; /* Largura do menu expandido em dispositivos móveis */
    
  }
}
