.content {
	margin: 0 auto;
	font-size: 19px;
	width: auto;
	color: #424242;
	background-color: #f5f7f5;
	display: grid;
	border-radius: 10px;
	padding: 15px 25px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Adiciona uma sombra ao redor da borda */
	padding-bottom: 5%;
}


/* Estilos da barra de navegação */
.nav {
	display: flex;
	width: auto;
	align-items: center;
	color: #424242;
	gap: 2%;
	flex-wrap: nowrap; /* Impede que os itens quebrem para a próxima linha */
	padding-bottom: 2%;
}

.navbar {
	padding: 10px;
	border-radius: 10px;
	color: #424242;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #a1a1a14d;
}

.active {
	padding: 10px;
	border-radius: 10px;
	color: #f2f2f2;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #c91a1a;
}
  
  .itensDestacados {
    border: 1px solid #c91a1a; /* Cor de destaque, ajuste conforme necessário */
    border-radius: 10px;
    background-color: #79df691c; /* Cor de fundo leve, ajuste conforme necessário */
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* 3 columns layout for desktop */
    gap: 20px;
    padding-left: 1%;
    padding-right: 1%;
  }
  
  /* Classe para selects que devem ser destacados */
  .selectDestacado {
    border-color: #007bff; /* Cor de destaque, ajuste conforme necessário */
  }
  
  label {
    display: block; /* Faz com que cada label ocupe uma linha inteira */
    font-weight: bold; /* Deixa o texto do label em negrito */
  }
  
  textarea {
    width: 100%; /* Faz com que o textarea ocupe toda a largura disponível */
    padding: 5px; /* Adiciona um pequeno preenchimento interno */
    font-size: 14px; /* Define o tamanho da fonte */
    border: 1px solid #ccc; /* Adiciona uma borda cinza */
    border-radius: 5px; /* Adiciona cantos arredondados */
    resize: vertical; /* Permite redimensionar verticalmente */
  }
  
  input[type="text"] {
    width: 100%; /* Faz com que o input ocupe toda a largura disponível */
    padding: 5px; /* Adiciona um pequeno preenchimento interno */
    font-size: 14px; /* Define o tamanho da fonte */
    border: 1px solid #ccc; /* Adiciona uma borda cinza */
    border-radius: 5px; /* Adiciona cantos arredondados */
  }
  
  
/* Grid layout for form fields */
.columns {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 3 columns layout for desktop */
    gap: 20px;
}

/* Grid layout for form fields */
.columnsmaq {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 3 columns layout for desktop */
  gap: 20px;
}

.nome, .cpf, .empresa, .status {
    width: 100%;
    font-size: 1rem;
}

.NomeGrupo, .NomeReduzido {
    width: 100%;
    font-size: 1rem;
}

.itens {
  padding-top: 1%;
}

.itens input {
  font-size: 1rem;
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 1rem;
  padding-top: 7%;
}

.masked-input {
    background-color: #fff; /* Example: white background */
    color: #333; /* Example: dark text */
    /* Additional specific styles for MaskedInput */
}

/* Cliente.module.css */

/* Estilos para o campo CNPJ */
.cnpj input {
    font-size: 1rem;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 1rem;
}

.cnpj Input {
  font-size: 1rem;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
}

label {
    display: block;overflow: unset;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

input[type="text"], input[type="CPF"], select {
    font-size: 1rem;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 1rem;
}

.submitButton {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 10px;
    width: 100%;
}

/* Estilos gerais da tabela */
.table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 14px;
  text-align: center;
  border: 1px solid #ddd;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1); /* Adicionando uma pequena sombra para dar profundidade */
  border-radius: 3px; /* Característica adicionada do primeiro CSS */
  overflow: hidden;   /* Característica adicionada do primeiro CSS */
}

/* Cabeçalho da tabela */
.table thead {
  background-color: #424242; /* Cor de fundo do cabeçalho */
  color: #ffffff; /* Cor do texto do cabeçalho */
}

.table thead th {
  padding: 8px 12px;
  border: 1px solid #ddd;
  font-weight: bold;
  background-color: #424242;
  color: #f2f2f2;
}

/* Corpo da tabela */
.table tbody td {
  padding: 8px 8px; /* Espaçamento interno nas células */
  border-top: 1px solid #ddd; /* Linha sutil entre as linhas */
}

/* Hover effect */
.table tbody tr:hover {
  background-color: #f5f5f5; /* Cor de fundo ao passar o mouse */
}

  .dataFiltro {
    display: flex;
    align-items: center;
    justify-content: end; /* Alinha os itens à direita */
    gap: 20px;
    margin-bottom: 10px;
    padding: 5px;
    padding-top: 3%;
    background-color: #f3f3f3;
    
  }
  
  .dataFiltro input[type="date"] {
    font-size: 1rem;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer; /* Melhora a interatividade */
  }
  
  .dataFiltro button {
    font-size: 1rem; /* Tamanho de fonte reduzido */
    border-radius: 3px; /* Menos curvatura nas bordas */
    border: none;
    cursor: pointer;
    background-color: #c91a1a;
    color: #ffffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  .dataFiltro button:hover {
    background-color: #367a31;
  }

  .dataFiltro .limparFiltros {
    width: auto ; /* Permite que o botão ajuste sua largura ao conteúdo, podendo adicionar 'max-width' para limitar */
    min-width: 100px ; /* Define uma largura mínima, ajuste conforme necessário */
    font-size: 0.9rem ;
    border-radius: 3px ;
    border: none ;
    cursor: pointer ;
    background-color: #c91a1a ;
    color: #ffffff ;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) ;
  }
  
  .dataFiltro .limparFiltros:hover {
    background-color: #e92c2c ;
  }

  .downloadbtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .downloadbtn .icon {
    color: #d82323; /* PDF icon color */
    font-size: 20px; /* Adjust the size of the icon */
  }
  
  
/* Responsiveness for mobile devices */
@media (max-width: 768px) {
    .content {
        font-size: 16px; /* Smaller font size for mobile */
        padding: 10px;
        width: 100%; /* Full width for mobile */
    }

    .nav {
        flex-direction: column; /* Stacking nav items vertically */
        width: 100%;
    }

    .columns {
        grid-template-columns: 1fr; /* Single column layout for mobile */
    }

    input[type="text"], select {
        font-size: 0.9rem; /* Adjusted font size for mobile */
    }

    .submitButton {
        margin-top: 15px;
    }

    .dataFiltro {
      flex-direction: column;
      gap: 10px;
    }
  
    .dataFiltro input[type="date"], .dataFiltro button {
      width: 100%; /* Faz os inputs e botão ocuparem toda a largura */
    }
}