/* Estilos da caixa de conteúdo */
.content {
	margin: 0 auto;
	font-size: 19px;
	width: auto;
	color: #424242;
	background-color: #f5f7f5;
	display: grid;
	border-radius: 10px;
	padding: 15px 25px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Adiciona uma sombra ao redor da borda */
	padding-bottom: 5%;
}


/* Estilos da barra de navegação */
.nav {
	display: flex;
	width: auto;
	align-items: center;
	color: #424242;
	gap: 2%;
	flex-wrap: nowrap; /* Impede que os itens quebrem para a próxima linha */
	padding-bottom: 2%;
}

.navbar {
	padding: 10px;
	border-radius: 10px;
	color: #424242;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #a1a1a14d;
}

.active {
	padding: 10px;
	border-radius: 10px;
	color: #f2f2f2;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #c91a1a;
}

/*-----------------------------------------------*/

.sumario {
	color: #8A909B;
	background-color: #F9FAFB;
	display: flex;
	justify-content: space-around;
	border-radius: 5px 5px 0 0;
	padding: 10px 0 ;
	border-bottom: 1px solid #E9EBEE;
	justify-items: center;
}

.sumario div {
	font-family: "Titillium Web", sans-serif;
	font-size: 18px;
	cursor: default;
	text-align: center;
}

.button_atraca{
	background-color: #424242;
	border-style: none;
	outline: none;
	border-radius: 80px;
	padding: 2px 13px;
	cursor: pointer;
	color: #fff;
	font-size: 16px;
	word-spacing: 5px;
	font-family: "Titillium Web", sans-serif;
	border: 1px solid #36b8e700;
	margin-right: 100px;
}


.button_atraca:hover {
	border: 1px solid #36b8e7;
	outline: 3px solid #8ad4ee80;
}

.btnRelatorio {
	padding-bottom: 40px;
	transition: background-color 0.3s ease;
}

/* Style for the dropdown container */
.dropdownContainer {
	margin-top: 20px; /* Adjust margin as needed */
	display: flex;
	align-items: center;
}

.navioDropdown {
	appearance: none;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 8px 12px;
	font-size: 16px;
	width: 100%;
	cursor: pointer;
	margin-right: 10px; /* Adjust margin as needed */
}

/* Style the dropdown arrow */
.navioDropdown::-ms-expand,
.navioDropdown::-ms-check {
	display: none;
}

.navioDropdown::-webkit-select-placeholder {
	color: #999; /* Placeholder text color */
}

.table_container {
	display: flex;
	flex-wrap: wrap; /* Os itens da tabela podem quebrar para a próxima linha quando necessário */
	gap: 20px; /* Espaçamento entre os itens da tabela */
	justify-content: center;
}

.table {
	margin-top: 1%;
}

.table_item {
	flex: 0 0 calc(33.33% - 20px); /* Largura de 33.33% para que haja 3 colunas por linha */
	border: 1px solid #ccc;
	padding: 22px;
	cursor: pointer;
	transition: background-color 0.2s ease, transform 0.2s ease;
	background-color: #f5f5f5;
	text-align: center;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra leve */
}

.table_item:hover {
	background-color: #c91a1a;
	color: #ffffff;
	transform: scale(1.05);
}

.detalheNavio {
	padding: 1px;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.table_outer_container {
	width: 100%;
	display: flex;
	justify-content: center;
}



.navio {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #8CA6DB;
	width: 80%;
	margin: 20px auto;
  }
  
  .cabine {
	background-color: #6D7F9B;
	width: 10%;
	height: 50px;
  }
  
  .porao {
	background-color: #5671B6;
	width: 15%;
	height: 100px;
	margin: 0 5px;
  }
  
  .proa {
	background-color: #6D7F9B;
	width: 10%;
	height: 30px;
  }
  

  .shipContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #b0c4de; /* Light steel blue for water */
	width: 100%;
	height: 200px; /* Adjust as necessary */
	margin: 20px 0;
  }
  
  .hold {
	width: 50px; /* Adjust as necessary */
	height: 100px; /* Adjust as necessary */
	background-color: #708090; /* Slate gray for the ship */
	margin: 0 5px;
	/* Add more styling as necessary */
  }


  