/* Estilos da caixa de conteúdo */
.content {
	margin: 0 auto;
	font-size: 19px;
	width: auto;
	color: #424242;
	background-color: #f5f7f5;
	display: grid;
	border-radius: 10px;
	padding: 15px 25px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Adiciona uma sombra ao redor da borda */
	padding-bottom: 5%;
}


/* Estilos da barra de navegação */
.nav {
	display: flex;
	width: auto;
	align-items: center;
	color: #424242;
	gap: 2%;
	flex-wrap: nowrap; /* Impede que os itens quebrem para a próxima linha */
	padding-bottom: 2%;
}

.navbar {
	padding: 10px;
	border-radius: 10px;
	color: #424242;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #a1a1a14d;
}

.active {
	padding: 10px;
	border-radius: 10px;
	color: #f2f2f2;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #c91a1a;
}

/*-----------------------------------------------*/

.flex {
	display: flex;
	justify-content: space-between;
	color: #153240;
	padding: 0 5px;
}


.form_control {
	width: 100%;
	margin-top: 2vh;
	font-size: 16px;
	height: 70px;
}

.form_control select{
	font-size: 16px;
	width: 96%;
	padding: 8px 5px;
	transition: 0.1s;
	margin-top: 3px;
	font-size: 15px;
	border-radius: 3px;
	border: 1px solid #ccc;
}

.form_control select:focus {
	border: 1px solid #8ad4ee;
	outline: 3px solid #8ad4ee80;
}

.navio{
	margin-top: 10px;
	font-size: 25px;
	font-weight: 700;
	color: #1F4D64;
}


.cargas {
	background-color: #F0F3F5;
	width: 100%;
	border: 1px dashed #153240a2;
	font-weight: 600;
	padding-top: 5px;
    text-align: center;
	color: #1F4D64;
}

.listatitulo {
	padding-top: 2%;
	font-size: 1em;
	font-weight: bold;
	margin-bottom: 20px;
  }
  
  .cargas {
	background-color: #f5f5f5;
	border-radius: 8px;
	padding: 15px;
	box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .sumario, .item {
	display: grid;
	grid-template-columns: repeat(7, 1fr) 40px; /* Define 7 colunas de tamanho igual para os dados e 1 menor para ações */
	align-items: center;
	text-align: center;
  }
  
  .sumario {
	background-color: #e4e4e4; /* Cor de fundo para o cabeçalho da tabela */
	font-weight: bold;
	border-radius: 8px 8px 0 0; /* Arredonda os cantos superiores */
	padding: 10px 0;
  }
  
  .item {
	border-bottom: 1px solid #ddd; /* Linhas de divisão entre as linhas da tabela */
	transition: background-color 0.3s; /* Suaviza a transição de cor de fundo */
  }
  
  .item:last-child {
	border-radius: 0 0 8px 8px; /* Arredonda os cantos inferiores da última linha */
	border-bottom: none;
  }
  
  .item:hover {
	background-color: #f5f5f5; /* Cor de fundo ao passar o mouse */
  }
  
  /* Estilos para Ações (Deletar, Editar) */
  .delete i, .edit i {
	cursor: pointer;
	transition: color 0.2s, transform 0.2s; /* Suaviza a transição de cor e transformação */
  }
  
  .delete i:hover, .edit i:hover {
	color: #c91a1a; /* Muda a cor ao passar o mouse */
	transform: scale(1.2); /* Aumenta o ícone */
  }
  
  /* Estilização Adicional */
  .total {
	display: flex;
	justify-content: space-between;
	padding: 10px;
	background-color: #e4e4e4;
	border-radius: 0 0 8px 8px; /* Arredonda os cantos inferiores */
	font-weight: bold;
  }
  
  .total div {
	padding: 5px 10px;
	background-color: #d9d9d9;
	border-radius: 5px;
  }

