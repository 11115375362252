.titulo_pagina {
    color: #000;
    font-family: roboto, -apple-system, BlinkMacSystemFont, segoe ui, oxygen, ubuntu, cantarell, fira sans, droid sans, helvetica neue, sans-serif;
    color: #424242;
    font-size: 28px;
    font-weight: 600;
    text-align: left;
    margin-left: 260px;
    width: 72%;
    margin-top: 1%;
}

.container {
    margin: 0 auto;
	width: auto;
	color: #424242;
	background-color: #f5f7f5;
	display: grid;
	border-radius: 20px;
	border: 1px solid #7ba776;
	padding: 15px 25px;
}

.nav {
	font-size: 25px;
	border-bottom: 1px solid #a3cc9f;
	display: flex;
	cursor: pointer;
}

.active {
	color: #424242;
	border-bottom: 3px solid #424242;
}

.texto2 {
    color: #424242;
    font-weight: bold;
    font-size: 19px;
    margin: 1px;
    margin-top: 3%;
    margin-left: 20px;
}

.textobase {
    color: #424242;
    font-size: 15px;
    margin: 1px;
    margin-top: 3%;
    margin-left: 20px;
}

.texto3 {
    color: #424242;
    font-size: 28px;
    margin: 1px;
    padding: 20px;
    padding-top: 3%;
    width: 60%;
    
}

.links_box {
    background-color: #ff000000;
    display: flex;
    justify-content: space-around;
    margin-top: 3%;
}

.quadrado {
    font-size: 16px;
    text-align: center;
    width: 20vw;
    height: 160px;
    border-radius: 9px;
    background-color: #f5f5f5;
    padding: 2px;
    box-shadow: 0 1px 2px 0 #424242, 0 10px 15px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.quadrado i {
    margin: auto;
    margin-top: 2vh;
    font-size: 45px;
}

.titulo{
    font-weight: 700;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

a{
    color: #424242;
}
a:hover{
    color: #424242;
}