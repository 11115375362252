.content {
	margin-bottom: 30px;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
  }

.tabContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #ddd;
}

.tabButton {
  padding: 5px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  outline: none;
}

.tabButton:hover {
  background-color: #0057b300;
}

.active {
  color: #0056b3;
  border-bottom: 3px solid #0056b3;
}

.iframeContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 20px;
}

.iframeGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Exibir 4 câmeras por linha */
  gap: 20px;
  margin-top: 20px;
}

.iframeWrapper {
  cursor: pointer; /* Indica que a câmera é clicável */
  position: relative;
  text-align: center;
}

.iframeWrapper p {
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
}

.modalContent {
  background-color: #fff;
  padding: 20px;
  width: 80%;
  max-width: 900px;
  text-align: center;
}

.closeButton {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ff4747;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.closeButton:hover {
  background-color: #ff2a2a;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); /* Fundo escuro para destacar o modal */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.noOverlay {
  pointer-events: none; /* Desativa interações com o iframe */
  border: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0; /* Permite o vídeo ser visível */
  pointer-events: none;
}

@media (max-width: 768px) {
  .modalContent {
    width: 90%;
  }
}
