.notform {
	text-align: center;
	font-size: 29px;
	padding: 150px;
	color: #424242;
}

.active {
	padding: 10px;
	border-radius: 10px;
	color: #f2f2f2;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #c91a1a;
}

/* Estilos da caixa de conteúdo */
.content {
	margin: 0 auto;
	font-size: 19px;
	width: auto;
	color: #424242;
	background-color: #f5f7f5;
	display: grid;
	border-radius: 10px;
	padding: 15px 25px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Adiciona uma sombra ao redor da borda */
	padding-bottom: 5%;
}
  
 /* Estilos da barra de navegação */
.nav {
	display: flex;
	width: auto;
	align-items: center;
	color: #424242;
	gap: 2%;
	flex-wrap: nowrap; /* Impede que os itens quebrem para a próxima linha */
	padding-bottom: 1%;
}

.navbar {
	padding: 10px;
	width: 100%;
	border-radius: 10px;
	color: #424242;
	border: 13px #424242;
	box-shadow: 0 2px 5px rgb(61, 60, 60);
	background-color: #a1a1a14d;
}

/*-----------------------------------------------*/

.flex {
	margin-top: 0.7vw;
	display: flex;
	justify-content: space-between;
	text-align: center;
	color: #424242;
	padding: 0 5px;
}

.flexPorao {
	margin-top: 0.7vw;
	display: flex;
	justify-content:space-around;
	text-align: center;
	color: #424242;
	padding: 0 5px;
}

.periodo {
	text-align: center;
	color: #424242;
	font-size: 27px;
	font-weight: 700;
}

.data {
	font-weight: 600;
	font-size: 22px;
	letter-spacing: -1px;
	margin-top: -8px;
	border-top: 2px solid #d9d9d9;
}


.status div {
	position: relative;
	top: 50%;
	font-size: 22px;
	font-weight: 600;
	transform: translateY(-50%);
	padding: 4px 7px;
	border-radius: 3px;
}

.OPERANDO {
	background-color: #bff6cd;
	color: #004e07;
}

.PARALISADO {
	background-color: #f79d9d;
	color: #790000;
}

.tara {
	background-color: #F0F3F5;
	width: 100%;
	border: 1px groove #424242a2;
	border-radius: 30px;
	font-weight: 600;
	padding-top: 5px;
}

.taratitulo {
	display: flex;
	justify-content: space-between;
	width: 90%;
	margin: 3% auto;
}

.taratitulo input {
	padding: 3px 10px;
	background-color: #FFFFFF;
	border: 1px solid #424242a2;
	border-radius: 4px;
}

.taratitulo input:focus {
	outline: 2px solid #8ad4ee80;
}

.taratitulo i {
	margin: 3px 3px 0px -20px;
	cursor: pointer
}

.sumario {
	display: flex;
	justify-content: space-around;
	margin: 5px 0;
}

.sumario div {
	border-right: 1px solid #42424273;
	width: 34%;
}

.lista {
	background-color: hsl(0, 0%, 100%);
	height: 200px;
	width: 95%;
	margin: auto;
	margin-bottom: 8px;
	overflow-y: auto;
}

.lista::-webkit-scrollbar {
	width: 10px;
}

.lista::-webkit-scrollbar-track {
	box-shadow: inset 0 0 3px grey;
}

.lista::-webkit-scrollbar-thumb {
	background: #424242;
	border-radius: 10px;
}

.item {
	display: flex;
	justify-content: space-between;
	border-top: 1px solid #d8d8d8;
	cursor: pointer;
}

.item:hover > .item_cell {
	background-color: #c7fff6;
	color: #585858;
}

.item_cell {
	width: 100%;
	font-weight: 400;
	color: #585858;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.item_status_def {
	background-color: #FFFFFF;
}

.item_status_2 {
	background-color: #FFC300;
}

.item_status_3 {
	background-color: #C70039;
}

.item_status_3 div {
	color: #FFFFFF;
}

.item_status_4 {
	background-color: #DAF7A6;
}

.item_status_5 {
	background-color: #FF5733;
}

.autos {
	width: 23%;
	background-color: #F0F3F5;
	border: 1px groove #424242a2;
	font-weight: 600;
	padding-top: 5px;
}

.autos div {
	font-size: 20px;
	padding: 7px 0;
}

.autos div i {
	font-size: 23px;
}

.colunadireita {
	width: 49%;
	border-radius: 30px;
	font-weight: 600;
	padding-top: 5px;
}

.paralisacao {
	background-color: #F0F3F5;
	width: 100%;
	border: 1px groove #424242a2;
	border-radius: 30px;
	font-weight: 600;
	padding-top: 5px;
	justify-content: space-around;
}

.conteudobox {
	background-color: #F0F3F5;
	width: 100%;
	border-radius: 30px;
	font-weight: 600;
	padding-top: 5px;
	display: flex;
	justify-content: space-around;
}

.sumariob {
	display: flex;
	justify-content: space-between;
	margin: 5px auto;
	width: 95%;
}

.motivobox {
	border-right: 1px solid #42424273;
	width: 78%;
}

.duracaobox {
	width: 20%;
}

.listab {
	background-color: #fff;
	height: 180px;
	width: 95%;
	margin: auto;
	margin-bottom: 8px;
	overflow-y: auto;
}

.lista::-webkit-scrollbar {
	width: 10px;
}

.lista::-webkit-scrollbar-track {
	box-shadow: inset 0 0 3px grey;
}

.lista::-webkit-scrollbar-thumb {
	background: #424242;
	border-radius: 10px;
}

.itemb {
	text-align: center;
	display: grid;
	grid-template-columns: 3.5fr 1fr;
	justify-content: space-between;
	border-top: 1px solid #d8d8d8;
	font-weight: 500;
}

.itemb div {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.pesos {
	background-color: #F0F3F5;
	width: 100%;
	border: 1px groove #424242a2;
	border-radius: 30px;
	font-weight: 600;
	padding-top: 5px;
	display: flex;
	justify-content: space-around;
}

.pesos div {
	width: 20%;
	font-weight: 700;
	font-size: 20px;
	border-right: 1px solid #42424273;
}

.pesos div div {
	border: none;
	width: 100%;
	color: #000;
	font-size: 18px;
	font-weight: 300;
	margin-top: -5px;
}


.abrirp {
	background-color: #b80000;
	border-radius: 35px;
	color: #ffffff;
	border: none;
	padding: 8px 28px;
	font-weight: 500;
	cursor: pointer;
}

.abrirp:hover {
	background-color: #ff0000;
}

.navio {
	width: flex;
	font-size: 25px;
	font-weight: 700;
}

.finalizar {
	background-color: #424242;
	color: #ffffff;
	border: none;
	padding: 8px 28px;
	font-weight: 500;
	border-radius: 35px;
	cursor: pointer;
}

.finalizar:hover {
	background-color: #00df5d;
}

.finalizar:disabled {
	background-color: #6f8b7b;
}

.finalizar:disabled:hover {
	background-color: auto;
	cursor: auto;
}

.line {
	display: flex;
	justify-content: space-between;
	flex-wrap: nowrap;
}

.inputline {
	display: flex;
	height: 25px;
	width: 90%;
	margin-bottom: 2px;
	flex-wrap: nowrap;
}

.buttontline {
	width: 40px;
	height: 25px;
	padding: 1%;
	margin-left: 2px;
	color: #ffffff;
	background-color: #424242;	
	border: none;	
	cursor: pointer;
}



.buttontlinecancel {
	width: 40px;
	height: 25px;
	padding: 1%;
	margin-left: 2px;
	color: red;
	background-color: red;
	margin-right: 3px;
	color: #ffffff;
	background-color: #ff0000;	
	border: none;	
	cursor: pointer;

}


.title {
	display: flex;
	flex-wrap: wrap;
}
.inputlinedate{
	width: 110%;
}

/* Estilos básicos para a área de seleção */

.pesos2 {
    display: flex;
    flex-direction: row;  /* alinha os elementos na horizontal */
    gap: 20px;            /* espaçamento entre os elementos */
}

.pesos2 label {
    padding: 13px;
    border: 2px solid #e0e0e0;
    border-radius: 5px;
    cursor: pointer; /* muda o cursor para indicar interação */
    transition: background-color 0.3s, color 0.3s;  /* suave transição de cores */
}

.pesos2 input[type="radio"] {
    display: none; /* esconde o input de rádio */
}

/* Estilos quando o rádio estiver selecionado */

.pesos2 input[type="radio"]:checked + label {
    background-color: #006400;
    color: white;
}

/* Estilo para ocultar labels e inputs após PORÃO #5 */

.pesos2 input[type="radio"]:nth-of-type(n+6),
.pesos2 label:nth-of-type(n+6) {
    display: none;
}

.flexContainer {
    display: flex;
    justify-content: space-between;
    text-align: center;
    color: #424242;
    align-items: center;
}

.flexContainerTotal {
    border: 1px solid #105a20;  /* Adds a border */
    border-radius: 20px;
}

.dataBox {
    flex: 1;
    display: flex;
	padding: 10px;
    flex-direction: column;
    align-items: center;
}

.dataBoxBT {
    display: flex;
	width: 35%;
	padding: 10px;
    justify-content:space-around;
    text-align: center;
    color: #424242;
    align-items: center;
}

.dataBox span {
    font-size: 18px;
    color: #000;
    line-height: 1.5;
}

.dataBox span:first-child {
    font-weight: 700;
    font-size: 20px;
}

/* Styles for the container */
.dataBox {
    display: flex;
    justify-content: center;  /* Centers the button horizontally */
    align-items: center;      /* Centers the button vertically */
    margin-bottom: 10px;     /* Provides space between the dataBox elements */
}

/* Styles specific for the button with the class 'dataButton' */
.dataButton {
	padding: 10px;
	border: 1px solid #105a20;  /* Adds a border */
    color: #105a20;             /* White text color */
    border-radius: 4px;      /* Rounded corners for the button */
    cursor: pointer;         /* Makes the mouse cursor look like a hand when hovering over the button */
    transition: background-color 0.3s; /* Adds a smooth color transition effect when hovering */
}

.dataButton:hover {
	color: #ffffff;  
    background-color: #09642f; /* Active button color */
}

.dataButtonActive {
	color: #ffffff;  
    background-color: #09642f; /* Active button color */
}
